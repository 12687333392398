import {Fragment, useState} from 'react';
import {KeyIcon} from '@heroicons/react/24/solid';
import {Menu, Tab, Transition} from '@headlessui/react';
import {ChevronDoubleRightIcon} from '@heroicons/react/24/outline';
import {format} from 'date-fns';
import {API, graphqlOperation} from 'aws-amplify';
import {classNames} from '../utils';
import {LoadingSpinner} from './LoadingSpinner';
import {createModerationCase, resolveModerationCase} from '../graphql/mutations';

export const ProfileDrawerTab = ({profile, updateCachedProfileHandler, openMediaItemHandler}) => {
  const [loading, setLoading] = useState(false);

  async function setFeatured() {
    try {
      console.log(`setting featured for user ${profile.id}`);
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(
          `mutation UpdateUser($userId: ID!, $input: UpdateUserInput!) {
                updateUser(userId: $userId, input: $input) {  
                  id
                }
            }`,
          {
            userId: profile.id,
            input: {
              featured: true
            }
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not update user`);
        console.error(response.errors);
        return;
      }
      updateCachedProfileHandler({
        profile: {
          ...profile,
          featured: true
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function unsetFeatured() {
    try {
      console.log(`unsetting featured for user ${profile.id}`);
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(
          `mutation UpdateUser($userId: ID!, $input: UpdateUserInput!) {
                updateUser(userId: $userId, input: $input) {  
                  id
                }
            }`,
          {
            userId: profile.id,
            input: {
              featured: false
            }
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not update user`);
        console.error(response.errors);
        return;
      }
      updateCachedProfileHandler({
        profile: {
          ...profile,
          featured: false
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function suspendUser() {
    try {
      console.log(`suspend user ${profile.id}`);
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(
          `mutation SuspendUser($userId: ID!) {
                suspendUser(userId: $userId) {  
                  result
                }
            }`,
          {
            userId: profile.id
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not suspend user`);
        console.error(response.errors);
        return;
      }
      updateCachedProfileHandler({
        profile: {
          ...profile,
          status: 'suspended'
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function unsuspendUser() {
    try {
      console.log(`unsuspend user ${profile.id}`);
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(
          `mutation UnsuspendUser($userId: ID!) {
                unsuspendUser(userId: $userId) {  
                  result
                }
            }`,
          {
            userId: profile.id
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not unsuspend user`);
        console.error(response.errors);
        return;
      }
      updateCachedProfileHandler({
        profile: {
          ...profile,
          status: 'active'
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function setReputation({reputation}) {
    try {
      console.log(`set reputation ${profile.id}`);
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(
          `mutation SetReputation($userId: ID!, $reputation: Float!) {
                updateUserReputation(userId: $userId, reputation: $reputation) {  
                  result
                }
            }`,
          {
            userId: profile.id,
            reputation
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not set reputation`);
        console.error(response.errors);
        return;
      }
      updateCachedProfileHandler({
        profile: {
          ...profile,
          reputation
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function deleteUser() {
    try {
      console.log(`deleting user ${profile.id}`);
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(
          `mutation DeleteUser($userId: ID!) {
                deleteUser(userId: $userId) {  
                  result
                }
            }`,
          {
            userId: profile.id
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not delete user`);
        console.error(response.errors);
        return;
      }
      updateCachedProfileHandler({
        profile: {
          ...profile,
          deleted: true
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function addFlag({comment}) {
    try {
      console.log(`adding flag to profile ${profile.id}`);
      setLoading(true);
      const result = await createModerationCase({targetId: profile.id, comment});
      if (result === 'success')
        updateCachedProfileHandler({
          profile: {
            ...profile,
            moderation: {
              ...profile.moderation,
              status: 'flagged',
              totalReports: profile.moderation?.totalReports || 1,
              color: 'blue',
              comment
            }
          }
        });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function removeFlag() {
    try {
      console.log(`resolving flagged profile ${profile.id}`);
      setLoading(true);
      const result = await resolveModerationCase({targetId: profile.id});
      if (result === 'success')
        updateCachedProfileHandler({
          profile: {
            ...profile,
            moderation: {
              ...profile.moderation,
              status: 'resolved'
            }
          }
        });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    profile && (
      <>
        <Tab.Panel>
          <div className="px-4 py-5 sm:px-0 sm:py-0">
            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200 ">
              <div className="sm:flex sm:px-6 sm:py-3">
                <ul role="list" className="grid grid-cols-4 gap-x-1 gap-y-2">
                  {profile.media?.length > 0 ? (
                    profile.media.map(media => (
                      <li key={media.url} className="relative">
                        <button onClick={() => openMediaItemHandler({media})}>
                          <img
                            src={media.url}
                            alt=""
                            className="object-cover pointer-events-none group-hover:opacity-75 h-56 mx-auto rounded-md"
                          />
                        </button>
                      </li>
                    ))
                  ) : (
                    <div>No Photos</div>
                  )}
                </ul>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">
                  Profile ID
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">{profile.id}</dd>
                <div className="ml-2 inline-flex">
                  <Menu as="div" className="relative inline-block text-right">
                    <Menu.Button
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white p-1 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-jade focus:ring-offset-2">
                      <ChevronDoubleRightIcon className="h-4 w-4" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none hover:opacity-100">
                        <div className="py-1">
                          <Menu.Item>
                            {({active}) => (
                              <span
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm font-bold'
                                )}
                              >
                                {profile.name}
                              </span>
                            )}
                          </Menu.Item>
                        </div>

                        {profile.featured ? (
                          <div className="py-1">
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  onClick={unsetFeatured}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                    'flex px-4 py-2 text-sm w-full'
                                  )}
                                >
                                  <span>Unmark as Featured</span>
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        ) : (
                          <div className="py-1">
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  onClick={setFeatured}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                    'flex px-4 py-2 text-sm w-full'
                                  )}
                                >
                                  <span>Mark as Featured</span>
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        )}

                        {profile.moderation?.status === 'flagged' ? (
                          <div className="py-1">
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  onClick={removeFlag}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                    'flex px-4 py-2 text-sm w-full'
                                  )}
                                >
                                  <span>Remove Flag</span>
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        ) : (
                          <div className="py-1">
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  onClick={addFlag}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                    'flex px-4 py-2 text-sm w-full'
                                  )}
                                >
                                  <span>Add Flag</span>
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        )}

                        <div className="py-1">
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={suspendUser}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm w-full'
                                )}
                              >
                                <span>Suspend</span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={unsuspendUser}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm w-full'
                                )}
                              >
                                <span>Unsuspend</span>
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                        <div className="py-1">
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={() => setReputation({reputation: 0.99})}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm w-full'
                                )}
                              >
                                <span>Good Rep</span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={() => setReputation({reputation: 0.01})}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm w-full'
                                )}
                              >
                                <span>Bad Rep (Shadowban)</span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={() => setReputation({reputation: 0.5})}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm w-full'
                                )}
                              >
                                <span>Reset Rep</span>
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                        <div className="py-1">
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={deleteUser}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm w-full'
                                )}
                              >
                                <span className="italic text-brand-amber">Delete Profile</span>
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <LoadingSpinner loading={loading} />
                </div>
              </div>

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">Real Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {profile.realName}
                </dd>
              </div>

              {profile.moderation?.status && (
                <div className="sm:flex sm:px-6 sm:py-3">
                  <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">
                    Moderation
                  </dt>
                  <dd
                    className={classNames(
                      profile.moderation?.status === 'flagged' ? 'text-brand-amber' : 'text-brand-black/90',
                      'mt-1 text-sm sm:col-span-2 sm:mt-0 sm:ml-6 self-center'
                    )}
                  >
                    {profile.moderation?.status === 'flagged'
                      ? `Flagged ${profile.moderation?.totalReports} ${
                        profile.moderation?.totalReports > 1 ? 'times' : 'time'
                      }`
                      : 'Resolved'}
                  </dd>
                </div>
              )}

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  <a href={`mailto:${profile.person?.email}`} target="_blank" rel="noopener noreferrer">
                    {profile.person?.email}
                  </a>
                </dd>
              </div>

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">Phone</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  <a href={`tel:${profile.person?.phone}`} target="_blank" rel="noopener noreferrer">
                    {profile.person?.phone}
                  </a>
                </dd>
              </div>

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">
                  Signs On With
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  <div className="flex align-middle justify-items-center">
                    {profile.authMethods.map((authMethod, index) => {
                      switch (authMethod) {
                        case 'signinwithapple':
                          return (
                            <svg
                              key={index}
                              xmlns="http://www.w3.org/2000/svg"
                              aria-label="Apple"
                              className="w-4"
                              role="img"
                              viewBox="0 0 512 512"
                            >
                              <title>Apple</title>
                              <rect width="512" height="512" rx="15%" fill="#555" />
                              <path
                                fill="#f2f2f2"
                                d="M410 334s-10 29-30 59c-5 9-29 43-58 43-21 0-35-15-62-15-33 0-46 15-67 15-11 1-22-5-34-16-77-73-81-181-52-225 18-29 48-47 81-48 26 0 54 17 65 17 8 0 50-20 74-18 33 3 56 15 73 38-49 24-66 117 10 150zM329 56c8 32-27 93-79 90-3-43 34-87 79-90z"
                              />
                            </svg>
                          );
                        case 'google':
                          return (
                            <svg
                              key={index}
                              xmlns="http://www.w3.org/2000/svg"
                              aria-label="Google"
                              role="img"
                              className="w-4"
                              viewBox="0 0 512 512"
                            >
                              <title>Google</title>
                              <rect width="512" height="512" rx="15%" fill="#fff" />
                              <path fill="#4285f4" d="M386 400c45-42 65-112 53-179H260v74h102c-4 24-18 44-38 57z" />
                              <path fill="#34a853" d="M90 341a192 192 0 0 0 296 59l-62-48c-53 35-141 22-171-60z" />
                              <path fill="#fbbc02" d="M153 292c-8-25-8-48 0-73l-63-49c-23 46-30 111 0 171z" />
                              <path fill="#ea4335" d="M153 219c22-69 116-109 179-50l55-54c-78-75-230-72-297 55z" />
                            </svg>
                          );
                        case 'facebook':
                          return (
                            <svg
                              key={index}
                              xmlns="http://www.w3.org/2000/svg"
                              aria-label="Facebook"
                              role="img"
                              className="w-4"
                              viewBox="0 0 512 512"
                            >
                              <title>Facebook</title>
                              <rect width="512" height="512" rx="15%" fill="#1877f2" />
                              <path
                                d="M355.6 330l11.4-74h-71v-48c0-20.2 9.9-40 41.7-40H370v-63s-29.3-5-57.3-5c-58.5 0-96.7 35.4-96.7 99.6V256h-65v74h65v182h80V330h59.6z"
                                fill="#fff"
                              />
                            </svg>
                          );
                        default:
                          return <KeyIcon key={index} className="mr-1 h-4 w-4 text-brand-green" aria-hidden="true" />;
                      }
                    })}
                  </div>
                </dd>
              </div>

              {profile.mobileDeviceInfo?.demographics?.platform && (
                <div className="sm:flex sm:px-6 sm:py-3">
                  <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">
                    Platform
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                    {profile.mobileDeviceInfo?.demographics?.platform}
                  </dd>
                </div>
              )}

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">Status</dt>
                <dd
                  className={classNames(
                    profile.status === 'suspended' ? 'text-brand-amber' : 'text-gray-900',
                    'mt-1 text-sm sm:col-span-2 sm:mt-0 sm:ml-6 self-center'
                  )}
                >
                  {profile.status}
                </dd>
              </div>

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">
                  Reputation
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {profile.reputation}
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">
                  Created At
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {profile.createdAt && format(new Date(profile.createdAt), 'MM/dd/yy hh:mm a')}
                </dd>
              </div>

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">
                  Last Seen At
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {profile.lastSeenAt && format(new Date(profile.lastSeenAt), 'MM/dd/yy hh:mm a')}
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">Age</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">{profile.age}</dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">Gender</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {profile.person?.gender}
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">Location</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {profile.location}
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">Tagline</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  <p>{profile.tagline}</p>
                </dd>
              </div>

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-24 self-center">Bio</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  <p>{profile.bio}</p>
                </dd>
              </div>
            </dl>
          </div>
        </Tab.Panel>
      </>
    )
  );
};
