import {useEffect, useState} from 'react';
import {Tab} from '@headlessui/react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {decoratePost} from '../utils';
import {profilePostsQuery} from '../graphql/queries';
import {PostCard} from './PostCard';

export const PostsDrawerTab = ({profile, setPostsTabCount}) => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: !!nextToken,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 300px 0px'
  });

  useEffect(() => {
    (async () => {
      try {
        const profileId = profile.id;
        if (profileId) await fetchPostsForSelectedProfile();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  async function handleLoadMore() {
    try {
      setLoading(true);
      await fetchPostsForSelectedProfile();
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchPostsForSelectedProfile() {
    try {
      const cachedPosts = [...posts];

      console.time('posts');
      const response = await profilePostsQuery({
        profileId: profile.id,
        nextToken,
        filters: {limit: 20}
      });
      console.timeEnd('posts');

      const postItems = [...cachedPosts, ...response.items.map(decoratePost)];
      setPosts(postItems);
      setNextToken(response.nextToken);
      setPostsTabCount(response.total);
    } catch (e) {
      console.error(e);
    }
  }

  function openInNewTab({post}) {
    if (!post?.id) return;
    window.open(`/posts?id=${post.id}`, '_blank', 'noopener,noreferrer');
  }

  return (
    <>
      <Tab.Panel>
        <div className="mx-auto py-6 px-4">
          {(!posts || posts.length === 0) && <div className="text-sm h-16 text-brand-black/70">No Posts</div>}
          {posts?.length > 0 && (
            <div className="grid grid-cols-2 gap-y-4 gap-x-6">
              {posts?.map(post => (
                <PostCard key={post.id} post={post} openDrawer={openInNewTab} setSelectedTabIndex={openInNewTab} />
              ))}
              <div>
                <div ref={sentryRef}></div>
                {nextToken ? (
                  <div className="border border-brand-jade shadow rounded-md p-4 max-w-sm w-full mx-auto">
                    <div className="animate-pulse flex space-x-4">
                      <div className="rounded-full bg-brand-jade/70 h-10 w-10"></div>
                      <div className="flex-1 space-y-6 py-1">
                        <div className="h-36 bg-brand-jade/40 rounded"></div>
                        <div className="space-y-3">
                          <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-brand-jade/70 rounded col-span-2"></div>
                            <div className="h-2 bg-brand-jade/70 rounded col-span-1"></div>
                          </div>
                          <div className="h-2 bg-brand-jade/70 rounded"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>No more results</div>
                )}
              </div>
            </div>
          )}
        </div>
      </Tab.Panel>
    </>
  );
};
