import {Tab} from '@headlessui/react';
import {classNames} from '../utils';

export const DrawerTabList = ({tabs}) => (
  <>
    <Tab.List className="-mb-px flex space-x-6 md:space-x-12 pl-6 border-b border-gray-200 focus:outline-none">
      {tabs.map(tab => (
        <Tab
          key={tab.name}
          className={({selected}) =>
            classNames(
              'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
              selected
                ? 'border-brand-jade text-brand-jade'
                : 'border-transparent text-brand-black/70 hover:text-brand-black/40 hover:border-brand-black/30'
            )
          }
        >
          {tab.name}
          {tab.count > 0 && <span className="pl-1 font-normal">({tab.count})</span>}
        </Tab>
      ))}
    </Tab.List>
  </>
);
