import {Tab} from '@headlessui/react';
import {useState} from 'react';
import {ProfileDrawerTab} from './ProfileDrawerTab';
import {PostDrawerTab} from './PostDrawerTab';
import {MediaItemViewer} from './MediaItemViewer';
import {PostCommentsDrawerTab} from './PostCommentsDrawerTab';
import {ModerationReportsDrawerTab} from './ModerationReportsDrawerTab';
import {PostReactionsDrawerTab} from './PostReactionsDrawerTab';

export const PostDrawerTabPanels = ({
  post,
  updateCachedPostHandler,
  updateCachedProfileHandler,
  setCommentsTabCount,
  setReactionsTabCount,
  setModerationTabCount
}) => {
  const [mediaItem, setMediaItem] = useState(undefined);

  function closeMediaItemViewer() {
    setMediaItem(undefined);
  }

  function openMediaItemViewer({media}) {
    setMediaItem(media);
  }

  return (
    post && (
      <>
        <Tab.Panels>
          <PostDrawerTab
            post={post}
            updateCachedPostHandler={updateCachedPostHandler}
            openMediaItemHandler={openMediaItemViewer}
          />
          <ProfileDrawerTab
            profile={post?.authorProfile}
            openMediaItemHandler={openMediaItemViewer}
            updateCachedProfileHandler={updateCachedProfileHandler}
          />
          <PostCommentsDrawerTab post={post} setCommentsTabCount={setCommentsTabCount} />
          <PostReactionsDrawerTab post={post} setReactionsTabCount={setReactionsTabCount} />
          <ModerationReportsDrawerTab target={post} setModerationTabCount={setModerationTabCount} />
        </Tab.Panels>
        <MediaItemViewer onClose={closeMediaItemViewer} mediaItem={mediaItem} />
      </>
    )
  );
};
