import {formatDistanceToNowStrict} from 'date-fns';
import {classNames} from '../utils';

export const Comment = ({comment, level, italicizeText = false}) =>
  comment && (
    <>
      <div className={classNames(level === 2 ? 'pl-10' : level === 3 ? 'pl-14' : 'pl-6', 'pr-4 sm:py-2')}>
        <div className="text-sm font-medium text-brand-black/40 self-center">
          <div className="flex pt-2 pb-1 pr-2">
            <div className="flex-shrink-0 pr-2">
              <img
                className={classNames(
                  comment?.authorProfile?.repRingColorClass,
                  'h-5 w-5 bg-brand-black/20 max-w-none rounded-full ring-2 object-cover object-center'
                )}
                src={comment?.authorProfile.cover || comment?.authorProfile?.avatar}
                alt=""
              />
            </div>
            <div className="min-w-0 flex-1">
              <div className="text-sm font-medium text-brand-black/90">
                {comment?.authorProfile?.name}
                <span className="text-xs px-2 text-brand-black/40">
                  {formatDistanceToNowStrict(new Date(comment.createdAt), {addSuffix: true})}
                </span>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              italicizeText && 'italic',
              'mt-1 text-sm font-normal tracking-tight text-brand-black/80 self-center'
            )}
          >
            {comment?.text}
          </div>
        </div>
      </div>
    </>
  );
