import {Fragment, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/24/solid';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {TabbedDrawer} from '../components/TabbedDrawer';
import {DrawerTabList} from '../components/DrawerTabList';
import {decorateProfile} from '../utils';
import {AppNavigation} from '../components/AppNavigation';
import {AppHeader} from '../components/AppHeader';
import {ProfileDrawerTabPanels} from '../components/ProfileDrawerTabPanels';
import {profileQuery, profilesQuery, search} from '../graphql/queries';
import {ProfileCard} from '../components/ProfileCard';
import {LoadingSpinner} from '../components/LoadingSpinner';

const sortOptions = [
  {id: 'new', name: 'Newest'},
  {id: 'recent', name: 'Recent Activity'},
  {id: 'flagged', name: 'Flagged'},
  {id: 'featured', name: 'Featured'}
];

export function ProfilesPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [profilesNextToken, setProfilesNextToken] = useState(undefined);
  const [selectedProfile, setSelectedProfile] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
  const [searchText, setSearchText] = useState(undefined);
  const [postsTabCount, setPostsTabCount] = useState(0);
  const [commentsTabCount, setCommentsTabCount] = useState(0);
  const [moderationTabCount, setModerationTabCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: !!profilesNextToken,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 300px 0px'
  });

  useEffect(() => {
    (async () => {
      try {
        const profileId = searchParams.get('id');
        const sort = searchParams.get('sort');
        const q = searchParams.get('q');
        if (sort)
          setSelectedSortOption(
            sort === 'recent'
              ? sortOptions[1]
              : sort === 'flagged'
              ? sortOptions[2]
              : sort === 'featured'
              ? sortOptions[3]
              : sortOptions[0]
          );
        if (profileId) await openProfileOnRender({profileId});
        else if (q) await searchHandler({text: q});
        else await fetchProfiles({nextToken: undefined});
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const tabs = [
    {name: 'Profile', current: true},
    {name: 'Posts', current: false, count: postsTabCount},
    {name: 'Comments', current: false, count: commentsTabCount},
    {name: 'Chat', current: false},
    {name: 'Moderation', current: false, count: moderationTabCount}
  ];

  async function closeDrawer() {
    setDrawerOpen(false);
    setSelectedProfile(undefined);
    searchParams.delete('id');
    setSearchParams(searchParams);
  }

  async function resetHandler() {
    try {
      setSearchText(undefined);
      await fetchProfiles({nextToken: undefined});
    } catch (e) {
      console.error('error resetting page');
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function searchHandler({text}) {
    try {
      if (!text || !text.trim()) return;

      setLoading(true);

      const lowerCaseText = text.trim().toLowerCase();
      const hashtags = lowerCaseText.includes('#')
        ? lowerCaseText
            .split(' ')
            .filter(a => a.includes('#'))
            .map(a => a.replace(/[^a-z\d]/gi, ''))
        : [];
      // const cleanedText = lowerCaseText.replace(/[\W_]+/g, ' ');

      console.log(`searching for text ${lowerCaseText}`);
      console.log(`searching for hashtags ${hashtags}`);

      setSearchText(text);

      const response = await search({
        text: lowerCaseText,
        hashtags,
        limitPerType: 100
      });

      setProfiles(response?.profiles?.items?.map(decorateProfile));

      // don't try to paginate search results for now
      setProfilesNextToken(undefined);
    } catch (e) {
      console.error('error searching profile');
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function openSelectedProfile({profileId}) {
    try {
      setLoading(true);
      const profileItem = await fetchProfile({profileId});
      setSelectedProfile(profileItem);
      searchParams.set('id', profileId);
      setSearchParams(searchParams);
      setDrawerOpen(true);
    } catch (e) {
      console.error('error fetching profile');
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function openProfileOnRender({profileId}) {
    try {
      setLoading(true);
      const profileItem = await fetchProfile({profileId});
      setSelectedProfile(profileItem);
      setProfiles([profileItem]);
      setProfilesNextToken(undefined);
      setDrawerOpen(true);
    } catch (e) {
      console.error('error fetching profile');
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function fetchProfile({profileId}) {
    try {
      setLoading(true);
      return decorateProfile(await profileQuery({profileId}));
    } catch (e) {
      console.error('error fetching profile');
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function fetchProfiles({nextToken, sort}) {
    try {
      setLoading(true);
      const cachedProfiles = (nextToken && [...profiles]) || [];
      const sortParam = sort || searchParams.get('sort');

      console.time('profiles');
      const response = await profilesQuery({
        nextToken,
        filters: {
          limit: 20,
          flaggedOnly: sortParam === 'flagged',
          featuredOnly: sortParam === 'featured'
        },
        sortBy: sortParam === 'recent' ? 'recentActivity' : 'newest'
      });
      console.timeEnd('profiles');

      const profileItems = [...cachedProfiles, ...response.items.map(decorateProfile)];
      setProfiles(profileItems);
      setProfilesNextToken(response.nextToken);
    } catch (e) {
      console.error('error fetching profiles');
      console.error(JSON.stringify(e));
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function handleLoadMore() {
    try {
      setLoading(true);
      await fetchProfiles({nextToken: profilesNextToken});
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function handleSortChange(sortOption) {
    try {
      setLoading(true);
      setSelectedSortOption(sortOption);
      searchParams.set('sort', sortOption.id);
      setSearchParams(searchParams);
      setProfilesNextToken(undefined);
      await fetchProfiles({nextToken: undefined, sort: sortOption.id});
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function updateCachedProfileHandler({profile}) {
    if (profile.deleted) {
      await closeDrawer();
      await fetchProfiles({nextToken: undefined});
    } else {
      setSelectedProfile(profile);
      setProfiles(profiles.filter(a => !a.deleted).map(a => (a.id === profile.id ? decorateProfile(profile) : a)));
    }
  }

  return (
    <>
      {/* setup hidden elements */}
      <TabbedDrawer
        title={selectedProfile?.name}
        tabList={<DrawerTabList tabs={tabs} />}
        tabPanels={
          <ProfileDrawerTabPanels
            profile={selectedProfile}
            updateCachedProfileHandler={updateCachedProfileHandler}
            setPostsTabCount={setPostsTabCount}
            setCommentsTabCount={setCommentsTabCount}
            setModerationTabCount={setModerationTabCount}
          />
        }
        isOpen={drawerOpen}
        closeHandler={closeDrawer}
      />

      {/* left and mobile nav */}
      <AppNavigation sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} selectedKey={'profiles'} />

      {/* setup main column */}
      <div className="lg:pl-48 flex flex-col">
        <AppHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          searchHandler={searchHandler}
          resetHandler={resetHandler}
        />

        {/* main content */}
        <main className="flex-1 lg:border-t lg:border-gray-200">
          <div className="px-4 mt-6 sm:px-6 lg:px-8">
            <h2 className="text-gray-500 text-xs text-left">
              <div className="inline-block uppercase tracking-wide font-medium">User Profiles</div>

              {searchText ? (
                <span className="px-2">
                  &gt; Searching for <span className="italic">{searchText}</span>
                </span>
              ) : (
                <Listbox value={selectedSortOption} onChange={handleSortChange} className="inline-block pl-4">
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-48 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-brand-jade focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-brand-orange/50 sm:text-sm">
                      <span className="block truncate">{selectedSortOption.name}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-brand-black/40" />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="z-10 w-48 absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {sortOptions.map(sortOption => (
                          <Listbox.Option
                            key={sortOption.id}
                            className={({active}) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active ? 'bg-brand-orange/10 text-brand-amber' : 'text-brand-black/90'
                              }`
                            }
                            value={sortOption}
                          >
                            {({selected}) => (
                              <>
                                <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                  {sortOption.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brand-orange">
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              )}
              <LoadingSpinner loading={loading} />
            </h2>
            <div className="mx-auto py-6 ">
              <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4 2xl:grid-cols-5">
                {profiles?.map(profile => (
                  <ProfileCard key={profile.id} profile={profile} openSelectedProfile={openSelectedProfile} />
                ))}
                <div>
                  <div ref={sentryRef}></div>
                  {profilesNextToken ? (
                    <div className="border border-brand-jade shadow rounded-md p-4 max-w-sm w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        <div className="rounded-full bg-brand-jade/70 h-10 w-10"></div>
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-36 bg-brand-jade/40 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-brand-jade/70 rounded col-span-2"></div>
                              <div className="h-2 bg-brand-jade/70 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-brand-jade/70 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>No more results</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
