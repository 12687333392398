import ReactPlayer from 'react-player/lazy';
import {classNames} from '../utils';

export const MediaGrid = ({media}) =>
  media && (
    <>
      <div className="flex flex-wrap overflow-hidden h-64 bg-gray-100">
        {media.slice(0, 4).map(item => (
          <div
            key={item.key}
            className={classNames(
              media.length === 1 ? 'w-full h-full' : media.length === 2 ? 'w-1/2 h-64' : 'w-1/2 h-32',
              'overflow-hidden'
            )}
          >
            {item.type === 'image' ? (
              <img src={item.url} alt="" className="h-full w-full pointer-events-none object-center object-cover" />
            ) : (
              <ReactPlayer
                url={item.url}
                controls={true}
                muted={true}
                width="100%"
                height="100%"
                className="object-cover bg-brand-black"
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
