import {Fragment} from 'react';
import ReactPlayer from 'react-player/lazy';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';

export const MediaItemViewer = ({mediaItem, onClose}) =>
  mediaItem && (
    <>
      <Transition.Root show={!!mediaItem} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full md:px-2 lg:px-4">
              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel className="flex transform transition md:max-w-xl lg:max-w-3xl">
                  <button
                    type="button"
                    className="absolute top-4 right-4 text-brand-black/80 hover:text-brand-black/50 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="rounded-lg bg-gray-100 max-h-screen">
                    {mediaItem.type === 'image' ? (
                      <img src={mediaItem.url} alt="" className="object-cover max-h-screen" />
                    ) : (
                      <ReactPlayer
                        url={mediaItem.url}
                        controls={true}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        className="object-cover bg-brand-black max-h-screen"
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
