import {useEffect, useState} from 'react';
import {Tab} from '@headlessui/react';
import {format, formatDistanceToNowStrict} from 'date-fns';
import {postModerationQuery, profileModerationQuery} from '../graphql/queries';
import {saveModerationNote} from '../graphql/mutations';
import {LoadingSpinner} from './LoadingSpinner';

export const ModerationReportsDrawerTab = ({target, setModerationTabCount}) => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [moderationReports, setModerationReports] = useState([]);
  const [moderationNote, setModerationNote] = useState('');

  useEffect(() => {
    (async () => {
      try {
        if (target?.id) await fetchModerationReports();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  async function fetchModerationReports() {
    try {
      console.time('moderation');
      const response = target?.id.startsWith('post-')
        ? await postModerationQuery({postId: target.id})
        : target?.id.startsWith('usr-')
        ? await profileModerationQuery({profileId: target.id})
        : undefined;
      console.timeEnd('moderation');
      setModerationReports(response?.reports);
      setModerationNote(response?.note);
      setModerationTabCount(response?.items?.length);
    } catch (e) {
      console.error(e);
    }
  }

  async function saveNote() {
    try {
      console.log('saving note');
      setDirty(false);
      setLoading(true);
      await saveModerationNote({targetId: target.id, note: moderationNote});
    } catch (e) {
      console.error(e);
      setDirty(true);
    } finally {
      setLoading(false);
    }
  }

  function handleModerationNoteChange(e) {
    setDirty(true);
    setModerationNote(e.target.value);
  }

  function openProfileInNewTab({profileId}) {
    if (!profileId) return;
    window.open(`/profiles?id=${profileId}`, '_blank', 'noopener,noreferrer');
  }

  return (
    <>
      <Tab.Panel>
        <div className="px-6 py-5 sm:px-0 sm:py-0">
          {moderationReports && moderationReports.length > 0 && (
            <div className="mt-6 px-6">
              <div className="align-middle inline-block min-w-full border-b border-gray-200">
                <table className="min-w-full border-l border-r border-gray-200">
                  <thead>
                    <tr className="py-3 border-t border-gray-200 text-left text-xs border-b border-gray-200 bg-gray-50 text-brand-black/50 uppercase tracking-wider">
                      <th className="table-cell font-normal py-3 px-3" scope="col">
                        Reported
                      </th>
                      <th className="table-cell font-normal py-3 px-3" scope="col">
                        Offense
                      </th>
                      <th className="table-cell font-normal py-3 px-3" scope="col">
                        Name
                      </th>
                      <th className="table-cell font-normal py-3 px-3 text-right" scope="col">
                        Profile ID
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    {moderationReports.map(report => (
                      <tr key={report.id} className="text-xs text-left text-brand-black/70">
                        <td
                          className="table-cell px-3 py-3 whitespace-nowrap"
                          title={format(new Date(report.createdAt), 'MM/dd/yyyy h:mmaaa')}
                        >
                          {formatDistanceToNowStrict(new Date(report.createdAt), {addSuffix: true})}
                        </td>
                        <td className="table-cell px-3 py-3 whitespace-nowrap">{report.offense}</td>
                        <td className="table-cell px-3 py-3 whitespace-nowrap">
                          <button
                            onClick={() => openProfileInNewTab({profileId: report.sourceId})}
                            className="hover:text-brand-jade align-middle"
                          >
                            {report.sourceName}
                          </button>
                        </td>
                        <td className="table-cell px-3 py-3 whitespace-nowrap text-right">
                          <button
                            onClick={() => openProfileInNewTab({profileId: report.sourceId})}
                            className="hover:text-brand-jade align-middle"
                          >
                            {report.sourceId}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className="min-w-0 flex-1 mt-6 px-6 text-sm text-brand-black/70">
            <div>
              <textarea
                rows={5}
                name="comment"
                id="comment"
                className="block w-full border-gray-200 focus:border-brand-jade focus:ring-brand-jade text-sm"
                placeholder="Add your moderation notes..."
                defaultValue={moderationNote}
                onChange={handleModerationNoteChange}
              />
            </div>

            <div className="mt-2 flex justify-end">
              {dirty && (
                <button
                  onClick={saveNote}
                  className="inline-flex items-center rounded-md border border-transparent bg-brand-jade px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-jade/70 focus:outline-none focus:ring-2 focus:ring-brand-jade focus:ring-offset-2"
                >
                  Save
                </button>
              )}
              {loading && <LoadingSpinner loading={loading} />}
            </div>
          </div>
        </div>
      </Tab.Panel>
    </>
  );
};
