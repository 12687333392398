import {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Dialog, Transition} from '@headlessui/react';
import {HomeIcon, NewspaperIcon, RectangleStackIcon, UserGroupIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {classNames} from '../utils';

const navigation = [
  {key: 'home', name: 'Home', path: '/', icon: HomeIcon},
  {key: 'profiles', name: 'Profiles', path: '/profiles', icon: UserGroupIcon},
  {key: 'posts', name: 'Posts', path: '/posts', icon: RectangleStackIcon},
  {key: 'stories', name: 'Stories', path: '/stories', icon: NewspaperIcon}
  // {key: 'comments', name: 'Comments', path: '#', icon: ChatIcon},
  // {key: 'moderation', name: 'Moderation', path: '#', icon: ShieldExclamationIcon}
  // {key: 'featured', name: 'Featured', path: '#', icon: StarIcon},
  // {key: 'rooms', name: 'Rooms', path: '/rooms', icon: SunIcon}
  // {key: 'trending', name: 'Trending', path: '#', icon: TrendingUpIcon}
];

const shortcuts = [
  //  {name: 'Influencers', path: '#', bgColorClass: 'bg-brand-jade'},
  // {name: 'Good Reps', path: '#', bgColorClass: 'bg-brand-green'},
  // {name: 'Neutral Reps', path: '#', bgColorClass: 'bg-brand-yellow'},
  // {name: 'Bad Reps', path: '#', bgColorClass: 'bg-brand-amber'}
];

export const AppNavigation = props => (
  <>
    <Transition.Root show={props.sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 lg:hidden" onClose={props.setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 flex z-40">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => props.setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src="/img/ht-logo-dark.png" alt="High There Logo" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <div className="space-y-1">
                    {navigation.map((item, index) => (
                      <Link
                        key={index}
                        to={item.path}
                        className={classNames(
                          props.selectedKey === item.key
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                          'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            props.selectedKey === item.key
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="mt-8">
                    <h3
                      className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                      id="mobile-teams-headline"
                    >
                      Shortcuts
                    </h3>
                    <div className="mt-1 space-y-1" role="group" aria-labelledby="mobile-teams-headline">
                      {shortcuts.map((item, index) => (
                        <Link
                          key={index}
                          to={item.path}
                          className="group flex items-center px-3 py-2 text-base leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <span
                            className={classNames(item.bgColorClass, 'w-2.5 h-2.5 mr-4 rounded-full')}
                            aria-hidden="true"
                          />
                          <span className="truncate">{item.name}</span>
                        </Link>
                      ))}
                    </div>
                  </div>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>

    {/* Static sidebar for desktop */}
    <div className="hidden lg:flex lg:flex-col lg:w-48 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-100">
      <div className="flex items-center flex-shrink-0 px-6">
        <img className="h-8 w-auto" src="/img/ht-logo-dark.png" alt="High There Logo" />
      </div>
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="mt-4 h-0 flex-1 flex flex-col overflow-y-auto">
        {/* Navigation */}
        <nav className="px-3 mt-6">
          <div className="space-y-1">
            {navigation.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={classNames(
                  props.selectedKey === item.key
                    ? 'bg-gray-200 text-gray-900'
                    : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                )}
              >
                <item.icon
                  className={classNames(
                    props.selectedKey === item.name ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                    'mr-3 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </Link>
            ))}
          </div>
          <div className="mt-8">
            {/* Secondary navigation */}
            <h3
              className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
              id="desktop-teams-headline"
            >
              Shortcuts
            </h3>
            <div className="mt-1 space-y-1" role="group" aria-labelledby="desktop-teams-headline">
              {shortcuts.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
                >
                  <span className={classNames(item.bgColorClass, 'w-2.5 h-2.5 mr-4 rounded-full')} aria-hidden="true" />
                  <span className="truncate">{item.name}</span>
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </div>
  </>
);
