import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';

export function SignInPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [signInError, setSignInError] = useState(false);

  useEffect(() => {
    (async () => {
      console.log('signing out');
      await Auth.signOut();
    })();
  }, [Auth]);

  const handleEmailInput = event => {
    setEmail(event.target.value);
  };
  const handlePasswordInput = event => {
    setPassword(event.target.value);
  };

  const handleSubmit = event => event.preventDefault();

  const handleSignIn = async () => {
    setSignInError(false);
    const formattedEmail = email?.trim().toLowerCase();
    if (!formattedEmail || formattedEmail.length === 0 || !password || password.length === 0) return;
    try {
      console.log(`signing in ${formattedEmail}`);
      await Auth.signIn(formattedEmail, password);
      try {
        // This will throw an error if the user is not yet authenticated:
        await Auth.currentSession();
        console.log('user is authenticated');
        navigate('/');
      } catch {
        console.log('the user did not enter the right creds');
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      setSignInError(true);
      console.log('error signing in', error);
    }
  };

  return (
    <>
      <div className="min-h-screen flex bg-black text-white">
        <div className="hidden lg:flex flex-1 flex-col flex items-center justify-center h-screen py-12 px-2 lg:px-8 bg-brand-black">
          <img className="w-44 h-auto" src="/img/ht-logo-color.png" alt="High There Logo" />
        </div>
        <div className="flex-1 flex flex-col items-center lg:justify-center py-12 px-2 lg:px-8">
          <div className="mt-12 md:mt-24 lg:hidden">
            <img className="w-36 h-auto" src="/img/ht-logo-color.png" alt="High There Logo" />
          </div>
          <div className="mx-auto">
            <div className="mt-24 lg:mt-0">
              <div className="mt-6 w-72 md:w-96">
                <form action="#" method="POST" className="space-y-8" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-white">
                      Email
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Your Email"
                        required
                        onChange={handleEmailInput}
                        className="appearance-none block w-full px-3 py-4 border border-white bg-black placeholder-brand-gray/50 focus:outline-none focus:ring-brand-jade focus:border-brand-jade sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label htmlFor="password" className="block text-sm font-medium text-white">
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        placeholder="Your Password"
                        required
                        onChange={handlePasswordInput}
                        className="appearance-none block w-full px-3 py-4 border border-white bg-black placeholder-brand-gray/50 focus:outline-none focus:ring-brand-jade focus:border-brand-jade sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="pt-3">
                    <button
                      type="submit"
                      className="w-full flex justify-center py-4 px-4 border border-transparent text-lg text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-jade"
                      onClick={handleSignIn}
                    >
                      Sign in
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 ml-2 mt-1 rotate-45 "
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={1}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
                      </svg>
                    </button>
                  </div>

                  <div className={!signInError ? ', hidden' : ''}>
                    <span className="block text-sm font-medium text-brand-orange">
                      Invalid username or password.
                      <br />
                      <br />
                      Click here to reset your password.
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
