import {Fragment, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/24/solid';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {decoratePost} from '../utils';
import {AppNavigation} from '../components/AppNavigation';
import {AppHeader} from '../components/AppHeader';
import {TabbedDrawer} from '../components/TabbedDrawer';
import {DrawerTabList} from '../components/DrawerTabList';
import {PostDrawerTabPanels} from '../components/PostDrawerTabPanels';
import {postQuery, postsQuery, search} from '../graphql/queries';
import {PostCard} from '../components/PostCard';
import {LoadingSpinner} from '../components/LoadingSpinner';

const sortOptions = [
  {id: 'new', name: 'Newest'},
  {id: 'recent', name: 'Recent Activity'},
  {id: 'comments', name: 'Most Comments'},
  {id: 'reactions', name: 'Most Reactions'},
  {id: 'flagged', name: 'Flagged'},
  {id: 'featured', name: 'Featured'}
];

const dayOptions = [
  {id: '1', name: 'Day'},
  {id: '7', name: 'Week'},
  {id: '30', name: 'Month'},
  {id: '365', name: 'Year'},
  {id: '5000', name: 'All Time'}
];

export function PostsPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(undefined);
  const [selectedPostComments, setSelectedPostComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postsNextToken, setPostsNextToken] = useState(undefined);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
  const [selectedDayOption, setSelectedDayOption] = useState(dayOptions[4]);
  const [searchText, setSearchText] = useState(undefined);
  const [commentsTabCount, setCommentsTabCount] = useState(0);
  const [reactionsTabCount, setReactionsTabCount] = useState(0);
  const [moderationTabCount, setModerationTabCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      try {
        const postId = searchParams.get('id');
        const sort = searchParams.get('sort');
        const days = searchParams.get('days');
        const q = searchParams.get('q');
        if (sort)
          setSelectedSortOption(
            sort === 'recent'
              ? sortOptions[1]
              : sort === 'comments'
              ? sortOptions[2]
              : sort === 'reactions'
              ? sortOptions[3]
              : sort === 'flagged'
              ? sortOptions[4]
              : sort === 'featured'
              ? sortOptions[5]
              : sortOptions[0]
          );
        setSelectedDayOption(
          days === '1'
            ? dayOptions[0]
            : days === '7'
            ? dayOptions[1]
            : days === '30'
            ? dayOptions[2]
            : days === '365'
            ? dayOptions[3]
            : dayOptions[4]
        );
        if (postId) await fetchPost({postId});
        else if (q) await searchHandler({text: q});
        else await fetchPosts({nextToken: undefined});
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  async function resetHandler() {
    try {
      setSearchText(undefined);
      await fetchPosts({nextToken: undefined});
    } catch (e) {
      console.error('error resetting page');
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function searchHandler({text}) {
    try {
      if (!text || !text.trim()) return;

      setLoading(true);

      const lowerCaseText = text.trim().toLowerCase();
      const hashtags = lowerCaseText.includes('#')
        ? lowerCaseText
            .split(' ')
            .filter(a => a.includes('#'))
            .map(a => a.replace(/[^a-z\d]/gi, ''))
        : [];
      const cleanedText = lowerCaseText.replace(/[\W_]+/g, ' ');

      setSearchText(text);

      console.log(`searching for text ${cleanedText}`);
      console.log(`searching for hashtags ${hashtags}`);

      const response = await search({
        text: cleanedText,
        hashtags,
        limitPerType: 100
      });

      setPosts(response?.posts?.items?.map(decoratePost));

      // don't try to paginate search results for now
      setPostsNextToken(undefined);
    } catch (e) {
      console.error('error searching posts');
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function handleLoadMore() {
    try {
      setLoading(true);
      await fetchPosts({nextToken: postsNextToken});
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  function closeDrawer() {
    setDrawerOpen(false);
    setSelectedPost(undefined);
    setSelectedPostComments([]);
    setSelectedTabIndex(0);
    setCommentsTabCount(0);
    setReactionsTabCount(0);
    setModerationTabCount(0);
    searchParams.delete('id');
    setSearchParams(searchParams);
  }

  function openDrawer({post}) {
    setSelectedPost(post);
    setSelectedPostComments([]);
    searchParams.set('id', post.id);
    setSearchParams(searchParams);
    setDrawerOpen(true);
  }

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: !!postsNextToken,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 300px 0px'
  });

  const tabs = [
    {name: 'Post', current: true},
    {name: 'Author', current: false},
    {name: 'Comments', current: false, count: commentsTabCount},
    {name: 'Reactions', current: false, count: reactionsTabCount},
    {name: 'Moderation', current: false, count: moderationTabCount}
  ];

  function updateCachedPostHandler({post}) {
    setSelectedPost(post);
    setPosts(posts.filter(a => !a.deleted).map(a => (a.id === post.id ? decoratePost(post) : a)));
    if (post.deleted) closeDrawer();
  }

  async function updateCachedProfileHandler({profile}) {
    if (profile.deleted) {
      closeDrawer();
      await fetchPosts();
    } else {
      updateCachedPostHandler({
        post: {
          ...selectedPost,
          authorProfile: profile
        }
      });
    }
  }

  async function fetchPost({postId}) {
    try {
      console.time('post');
      const postItem = decoratePost(await postQuery({postId}));
      console.timeEnd('post');

      setPosts([postItem]);
      setPostsNextToken(undefined);
      openDrawer({post: postItem});
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchPosts({nextToken, sort, previousDays}) {
    try {
      const cachedPosts = (nextToken && [...posts]) || [];
      const sortParam = sort || searchParams.get('sort');

      console.time('posts');
      const response = await postsQuery({
        nextToken,
        filters: {
          limit: 20,
          flaggedOnly: sortParam === 'flagged',
          featuredOnly: sortParam === 'featured',
          previousDays
        },
        sortBy:
          sortParam === 'recent'
            ? 'recentActivity'
            : sortParam === 'comments'
            ? 'comments'
            : sortParam === 'reactions'
            ? 'reactions'
            : 'newest'
      });
      console.timeEnd('posts');

      const postItems = [...cachedPosts, ...response.items.map(decoratePost)];
      setPosts(postItems);
      setPostsNextToken(response.nextToken);
    } catch (e) {
      console.error(e);
    }
  }

  async function handleSortChange(sortOption) {
    try {
      setLoading(true);
      setSelectedSortOption(sortOption);
      searchParams.set('sort', sortOption.id);
      setSearchParams(searchParams);
      setPostsNextToken(undefined);
      await fetchPosts({nextToken: undefined, sort: sortOption.id});
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function handleDayChange(dayOption) {
    try {
      setLoading(true);
      setSelectedDayOption(dayOption);
      searchParams.set('days', dayOption.id);
      setSearchParams(searchParams);
      setPostsNextToken(undefined);
      await fetchPosts({nextToken: undefined, sort: selectedSortOption.id, previousDays: dayOption.id});
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      {/* setup hidden elements */}
      <TabbedDrawer
        title={selectedPost ? `${selectedPost.authorProfile?.name}'s Post` : ''}
        tabList={<DrawerTabList tabs={tabs} selectedIndex={selectedTabIndex} />}
        tabPanels={
          <PostDrawerTabPanels
            post={selectedPost}
            comments={selectedPostComments}
            updateCachedPostHandler={updateCachedPostHandler}
            updateCachedProfileHandler={updateCachedProfileHandler}
            setCommentsTabCount={setCommentsTabCount}
            setReactionsTabCount={setReactionsTabCount}
            setModerationTabCount={setModerationTabCount}
          />
        }
        isOpen={drawerOpen}
        closeHandler={closeDrawer}
        defaultIndex={selectedTabIndex}
      />

      <div className="min-h-full">
        {/* left and mobile nav */}
        <AppNavigation sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} selectedKey={'posts'} />

        {/* setup main column */}
        <div className="lg:pl-48 flex flex-col">
          <AppHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            searchHandler={searchHandler}
            resetHandler={resetHandler}
          />

          {/* main content */}
          <main className="flex-1 lg:border-t lg:border-gray-200">
            <div className="px-4 mt-6 sm:px-6 lg:px-8">
              <h2 className="text-gray-500 text-xs text-left">
                <div className="inline-block uppercase tracking-wide font-medium">User Posts</div>

                {searchText ? (
                  <span className="px-2">
                    &gt; Searching for <span className="italic">{searchText}</span>
                  </span>
                ) : (
                  <>
                    <Listbox value={selectedSortOption} onChange={handleSortChange} className="inline-block pl-4">
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-48 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-brand-jade focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-brand-orange/50 sm:text-sm">
                          <span className="block truncate">{selectedSortOption.name}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-brand-black/40" />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="z-10 w-48 absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {sortOptions.map(sortOption => (
                              <Listbox.Option
                                key={sortOption.id}
                                className={({active}) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active ? 'bg-brand-orange/10 text-brand-amber' : 'text-brand-black/90'
                                  }`
                                }
                                value={sortOption}
                              >
                                {({selected}) => (
                                  <>
                                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                      {sortOption.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brand-orange">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                    <Listbox value={selectedDayOption} onChange={handleDayChange} className="inline-block pl-4">
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-48 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-brand-jade focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-brand-orange/50 sm:text-sm">
                          <span className="block truncate">{selectedDayOption.name}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-brand-black/40" />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="z-10 w-48 absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {dayOptions.map(sortOption => (
                              <Listbox.Option
                                key={sortOption.id}
                                className={({active}) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active ? 'bg-brand-orange/10 text-brand-amber' : 'text-brand-black/90'
                                  }`
                                }
                                value={sortOption}
                              >
                                {({selected}) => (
                                  <>
                                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                      {sortOption.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brand-orange">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </>
                )}

                <LoadingSpinner loading={loading} />
              </h2>

              <div className="mx-auto py-6 ">
                <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4 2xl:grid-cols-5">
                  {posts?.map(post => (
                    <PostCard
                      key={post.id}
                      post={post}
                      openDrawer={openDrawer}
                      setSelectedTabIndex={setSelectedTabIndex}
                    />
                  ))}
                  <div>
                    <div ref={sentryRef}></div>
                    {postsNextToken ? (
                      <div className="border border-brand-jade shadow rounded-md p-4 max-w-sm w-full mx-auto">
                        <div className="animate-pulse flex space-x-4">
                          <div className="rounded-full bg-brand-jade/70 h-10 w-10"></div>
                          <div className="flex-1 space-y-6 py-1">
                            <div className="h-36 bg-brand-jade/40 rounded"></div>
                            <div className="space-y-3">
                              <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-brand-jade/70 rounded col-span-2"></div>
                                <div className="h-2 bg-brand-jade/70 rounded col-span-1"></div>
                              </div>
                              <div className="h-2 bg-brand-jade/70 rounded"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>No more results</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
