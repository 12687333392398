import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {API, graphqlOperation} from 'aws-amplify';
import {LockClosedIcon} from '@heroicons/react/24/outline';
import {
  BookmarkIcon,
  ChatBubbleOvalLeftEllipsisIcon as ChatIconSolid,
  ChevronRightIcon,
  FlagIcon,
  HeartIcon,
  RectangleStackIcon as CollectionIconSolid,
  UserPlusIcon,
  UsersIcon
} from '@heroicons/react/24/solid';
import {formatDistanceToNowStrict} from 'date-fns';
import {classNames, decoratePost, decorateProfile} from '../utils';
import {AppNavigation} from '../components/AppNavigation';
import {AppHeader} from '../components/AppHeader';
import {search} from '../graphql/queries';
import {ProfileCard} from '../components/ProfileCard';
import {PostCard} from '../components/PostCard';
import {LoadingSpinner} from '../components/LoadingSpinner';

const getQueryLimitForScreenSize = () =>
  window.innerWidth < 768 ? 4 : window.innerWidth < 1280 ? 3 : window.innerWidth - 192 < 1280 + 64 ? 4 : 5;

export function DashboardPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [recentProfiles, setRecentProfiles] = useState([]);
  const [recentAnalytics, setRecentAnalytics] = useState([
    {name: 'Users', href: '/profiles', icon: UsersIcon, amount: 0},
    {name: 'New Users', href: '/profiles?sort=new', icon: UserPlusIcon, amount: 0},
    {name: 'Flagged Posts', href: '/posts?sort=flagged', icon: FlagIcon, amount: 0},
    {name: 'Flagged Users', href: '/profiles?sort=flagged', icon: FlagIcon, amount: 0},
    {name: 'Connections', href: '#', icon: BookmarkIcon, amount: 0},
    {name: 'Posts', href: '/posts', icon: CollectionIconSolid, amount: 0},
    {name: 'Comments', href: '/comments', icon: ChatIconSolid, amount: 0},
    {name: 'Reactions', href: '/reactions', icon: HeartIcon, amount: 0}
  ]);
  const [searchText, setSearchText] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([fetchRecentAnalytics(), fetchRecentProfiles()]);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  async function resetHandler() {
    try {
      setLoading(true);
      setSearchText(undefined);
      await Promise.all([fetchRecentAnalytics(), fetchRecentProfiles()]);
      setPosts([]);
      setProfiles([]);
    } catch (e) {
      console.error('error resetting page');
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function searchHandler({text}) {
    try {
      if (!text || !text.trim()) return;

      setLoading(true);

      const lowerCaseText = text.trim().toLowerCase();
      const hashtags = lowerCaseText.includes('#')
        ? lowerCaseText
            .split(' ')
            .filter(a => a.includes('#'))
            .map(a => a.replace(/[^a-z\d]/gi, ''))
        : [];
      // const cleanedText = lowerCaseText.replace(/[\W_]+/g, ' ');

      console.log(`searching for text ${lowerCaseText}`);
      console.log(`searching for hashtags ${hashtags}`);

      setSearchText(text);

      const response = await search({
        text: lowerCaseText,
        hashtags,
        limitPerType: getQueryLimitForScreenSize()
      });

      setProfiles(response?.profiles?.items?.map(decorateProfile));
      setPosts(response?.posts?.items?.map(decoratePost));
    } catch (e) {
      console.error('error searching profile');
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function fetchRecentAnalytics() {
    try {
      console.time('analytics');
      const response = await API.graphql(
        graphqlOperation(`query Analytics {
                    platformAnalyticsTotals {
                    comments
                    follows
                    lives
                    posts
                    newUsers
                    reactions
                    reports
                    flaggedPosts
                    flaggedUsers
                    users
                }
            }`)
      );
      console.timeEnd('analytics');
      setRecentAnalytics([
        {
          name: 'Users',
          href: '/profiles?sort=recent',
          icon: UsersIcon,
          amount: response.data.platformAnalyticsTotals.users.toLocaleString('en-US')
        },
        {
          name: 'New Users',
          href: '/profiles?sort=new',
          icon: UserPlusIcon,
          amount: response.data.platformAnalyticsTotals.newUsers.toLocaleString('en-US')
        },
        {
          name: 'Flagged Posts',
          href: '/posts?sort=flagged',
          icon: FlagIcon,
          amount: response.data.platformAnalyticsTotals.flaggedPosts.toLocaleString('en-US')
        },
        {
          name: 'Flagged Users',
          href: '/profiles?sort=flagged',
          icon: FlagIcon,
          amount: response.data.platformAnalyticsTotals.flaggedUsers.toLocaleString('en-US')
        },
        {
          name: 'Connections',
          href: '#',
          icon: BookmarkIcon,
          amount: response.data.platformAnalyticsTotals.follows.toLocaleString('en-US')
        },
        {
          name: 'Posts',
          href: '/posts?sort=new',
          icon: CollectionIconSolid,
          amount: response.data.platformAnalyticsTotals.posts.toLocaleString('en-US')
        },
        {
          name: 'Comments',
          href: '/comments',
          icon: ChatIconSolid,
          amount: response.data.platformAnalyticsTotals.comments.toLocaleString('en-US')
        },
        {
          name: 'Reactions',
          href: '/reactions',
          icon: HeartIcon,
          amount: response.data.platformAnalyticsTotals.reactions.toLocaleString('en-US')
        }
      ]);
    } catch (e) {
      console.error('error fetching recent analytics');
      console.error(e);
    }
  }

  async function fetchRecentProfiles() {
    try {
      console.time('profiles');
      const response = await API.graphql(
        graphqlOperation(
          `query Profiles($filters: QueryFiltersInput, $sortBy: SortBy) {
            profiles(filters: $filters, sortBy: $sortBy) {
              items {
                ... on UserSummary {
                  id
                  name
                  person {
                    dob
                    email
                    familyName
                    gender
                    givenName
                    phone
                  }
                  roles
                  lastSeenAt
                  interestsFormatted
                  createdAt
                  cover
                  counts {
                    comments
                    connectionRequests
                    connections
                    posts
                    reactions
                  }
                  location {
                    locality
                    region
                    country
                  }
                  media {
                    url
                  }
                  bio
                  avatar
                  age
                  status
                  tagline
                  updatedAt
                  reputation
                }
              }
            }
          }`,
          {
            filters: {limit: 50},
            sortBy: 'recentActivity'
          }
        )
      );
      console.timeEnd('profiles');
      setRecentProfiles(response.data.profiles.items?.map(decorateProfile));
    } catch (e) {
      console.error('error fetching profiles');
      console.error(e);
    }
  }

  function handleLoadProfile({profile}) {
    console.log(`loading profile ${profile.id}`);
    navigate(`/profiles?id=${profile.id}`);
  }

  function openSelectedProfile({profileId}) {
    console.log(`loading profile ${profileId}`);
    navigate(`/profiles?id=${profileId}`);
  }

  function handleLoadPost({post}) {
    console.log(`loading post ${post.id}`);
    navigate(`/posts?id=${post.id}`);
  }

  return (
    <>
      <div className="min-h-full">
        {/* left and mobile nav */}
        <AppNavigation sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} selectedKey={'home'} />

        {/* setup main column */}
        <div className="lg:pl-48 flex flex-col">
          <AppHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            searchHandler={searchHandler}
            resetHandler={resetHandler}
          />

          {/* Main column */}
          <main className="flex-1 lg:border-t lg:border-gray-200">
            {/* Recent Analytics Cards */}
            <div className="px-4 mt-6 sm:px-6 lg:px-8 ">
              <h2 className="text-gray-500 text-xs text-left">
                {!searchText && (
                  <div>
                    <div className="text-gray-500 text-xs font-medium uppercase tracking-wide">Past 24 Hours</div>
                    <LoadingSpinner loading={loading} />
                  </div>
                )}
              </h2>

              {searchText ? (
                <div className="mx-auto">
                  {profiles?.length > 0 && (
                    <div>
                      <div className="grid grid-cols-2 text-gray-500 text-xs pb-4">
                        <span>
                          <span className="font-medium uppercase tracking-wide">User Profiles</span>
                          <span className="px-2">
                            &gt; Searching for <span className="italic pr-4">{searchText}</span>
                            <LoadingSpinner loading={loading} />
                          </span>
                        </span>
                        <span className="text-right">
                          <Link
                            to={`/profiles?q=${encodeURIComponent(searchText)}`}
                            className="hover:text-brand-orange"
                          >
                            View All
                          </Link>
                        </span>
                      </div>
                      <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4 2xl:grid-cols-5">
                        {profiles?.map(profile => (
                          <ProfileCard key={profile.id} profile={profile} openSelectedProfile={openSelectedProfile} />
                        ))}
                      </div>
                    </div>
                  )}
                  {posts?.length > 0 && (
                    <div>
                      <div
                        className={classNames(
                          profiles?.length > 0 && 'pt-8',
                          'grid grid-cols-2 text-gray-500 text-xs pb-4'
                        )}
                      >
                        <span>
                          <span className="font-medium uppercase tracking-wide">Posts</span>
                          <span className="px-2">
                            &gt; Searching for <span className="italic pr-4">{searchText}</span>
                          </span>
                          {!profiles || (profiles.length === 0 && <LoadingSpinner loading={loading} />)}
                        </span>
                        <span className="text-right ">
                          <Link to={`/posts?q=${encodeURIComponent(searchText)}`} className="hover:text-brand-orange">
                            View All
                          </Link>
                        </span>
                      </div>
                      <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4 2xl:grid-cols-5">
                        {posts?.map(post => (
                          <PostCard key={post.id} post={post} openDrawer={handleLoadPost} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="mt-2 grid grid-cols-2 gap-4 md:grid-cols-4">
                  {/* Card */}
                  {recentAnalytics.map(card => (
                    <div key={card.name} className="bg-white overflow-hidden border-gray-200 border rounded-md">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <card.icon className="h-6 w-6 text-brand-jade/60" aria-hidden="true" />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm font-medium text-brand-black/70 truncate">{card.name}</dt>
                              <dd>
                                <div className="text-lg font-medium text-brand-black/90">{card.amount}</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <a href={card.href} className="font-medium text-brand-jade hover:text-brand-jade/70">
                            View all
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Recent users list (only on smallest breakpoint) */}
            <div className="mt-10 sm:hidden">
              <div className="px-4 sm:px-6">
                <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">Recent Users</h2>
              </div>
              <ul role="list" className="mt-3 border-t border-gray-200 divide-y divide-gray-100">
                {recentProfiles.map(profile => (
                  <button key={profile.id} onClick={() => handleLoadProfile({profile})}>
                    <li className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
                      <span className="flex items-center space-x-3">
                        <img
                          className={classNames(
                            profile.repRingColorClass,
                            'hover:ring-brand-jade max-w-none bg-brand-black/20 h-6 w-6 rounded-full ring-2 object-cover object-center'
                          )}
                          src={profile.cover || profile.avatar}
                          alt={profile.name}
                        />
                        <span className="font-medium truncate text-sm leading-6">
                          {profile.name}
                          <span className="truncate font-normal text-gray-500 px-2">({profile.realName})</span>
                          {profile.status === 'suspended' && (
                            <LockClosedIcon className="inline h-4 w-4 font-normal text-gray-500" aria-hidden="true" />
                          )}
                        </span>
                      </span>
                      <ChevronRightIcon
                        className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </li>
                  </button>
                ))}
              </ul>
            </div>

            {/* Projects table (small breakpoint and up) */}
            <div className="hidden mt-8 sm:block">
              <div className="align-middle inline-block min-w-full border-b border-gray-200">
                <table className="min-w-full">
                  <thead>
                    <tr className="border-t border-gray-200">
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="lg:pl-2">Recent Users</span>
                      </th>
                      <th
                        className="hidden 2xl:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Email
                      </th>
                      <th
                        className="hidden xl:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Last Seen
                      </th>
                      <th
                        className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Posts
                      </th>
                      <th
                        className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Comments
                      </th>
                      <th
                        className="hidden sm:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Rep
                      </th>
                      <th
                        className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      />
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    {recentProfiles.map(profile => (
                      <tr key={profile.id}>
                        <td className="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                          <button
                            onClick={() => handleLoadProfile({profile})}
                            className="hover:text-brand-jade align-middle"
                          >
                            <div className="flex items-center space-x-3 lg:pl-2">
                              <div className="flex-shrink-0">
                                <img
                                  className={classNames(
                                    profile.repRingColorClass,
                                    'hover:ring-brand-jade max-w-none bg-brand-black/20 h-6 w-6 rounded-full ring-2 object-cover object-center'
                                  )}
                                  src={profile.cover || profile.avatar}
                                  alt=""
                                />
                              </div>
                              <span className="truncate">
                                {profile.name}
                                <span className="text-gray-500 font-normal px-2">({profile.realName})</span>
                                {profile.status === 'suspended' && (
                                  <LockClosedIcon
                                    className="inline h-4 w-4 font-normal text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </div>
                          </button>
                        </td>
                        <td className="hidden 2xl:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
                          {profile.person?.email}
                        </td>
                        <td className="hidden xl:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
                          {formatDistanceToNowStrict(new Date(profile.lastSeenAt), {addSuffix: true})}
                        </td>
                        <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center">
                          {profile.counts?.posts || 0}
                        </td>
                        <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center">
                          {profile.counts?.comments || 0}
                        </td>
                        <td className="hidden sm:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                          {profile.reputation}
                        </td>

                        <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => handleLoadProfile({profile})}
                            className="hover:text-brand-jade align-middle"
                          >
                            <ChevronRightIcon
                              className="h-6 w-6 text-brand-gray/50 hover:text-brand-jade"
                              aria-hidden="true"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
