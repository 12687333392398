import {format} from 'date-fns';
import {ChatBubbleLeftEllipsisIcon, FlagIcon, HeartIcon} from '@heroicons/react/24/outline';
import {StarIcon} from '@heroicons/react/20/solid';
import {classNames} from '../utils';
import {MediaGrid} from './MediaGrid';

export const PostCard = ({post, comment, openDrawer, setSelectedTabIndex}) =>
  post && (
    <>
      <div className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden shadow-sm hover:shadow-md">
        {/* post author */}
        <button onClick={() => openDrawer({post})} className="w-full text-left focus:outline-none">
          <div className="flex space-x-2 pt-2 pl-2 pb-2 pr-2">
            <div onClick={() => setSelectedTabIndex(1)} className="flex-shrink-0">
              <img
                className={classNames(
                  post?.authorProfile?.repRingColorClass,
                  'h-8 w-8 bg-brand-black/20 max-w-none rounded-full ring-2 object-cover object-center'
                )}
                src={post?.authorProfile?.cover || post?.authorProfile?.avatar}
                alt=""
              />
            </div>
            <div className="min-w-0 flex-1">
              <div
                onClick={() => setSelectedTabIndex(1)}
                className="text-sm font-medium text-brand-black/90 hover:underline"
              >
                {post?.authorProfile?.name}
              </div>
              <div className="group flex rounded-md text-xs text-brand-black/50 items-center tracking-tight">
                <time className="pr-8" dateTime={post?.createdAt}>
                  {format(new Date(post?.createdAt), 'MM/dd/yy hh:mm a')}
                </time>
              </div>
            </div>
            {post.moderation?.status === 'flagged' && (
              <FlagIcon
                className={classNames(
                  post.moderation.color === 'blue' ? 'text-brand-jade' : 'text-brand-amber',
                  'h-5 w-5'
                )}
              />
            )}
          </div>
        </button>

        {/* post media carousel */}
        <div className="bg-gray-100 h-64">
          {/* TODO click the carousel to open the drawer with the side arrows still functional */}
          {!post?.media?.length ? (
            <button onClick={() => openDrawer({post})} className="w-full h-64">
              &nbsp;
            </button>
          ) : !post?.media?.some(a => a.type === 'video') ? (
            <button onClick={() => openDrawer({post})} className="w-full">
              <MediaGrid media={post.media} />
            </button>
          ) : (
            <MediaGrid media={post.media} />
          )}
        </div>
        {/* post title and info */}
        <button onClick={() => openDrawer({post})} className="w-full h-full text-left focus:outline-none">
          <div className="flex-1 p-4 space-y-2 flex flex-col">
            <div className="text-xs text-brand-black h-6">
              {comment?.text ? (
                <div>
                  <p className="line-clamp-1 font-medium">{post.title}</p>
                  <p className="line-clamp-3 pt-2 italic">Commented: &ldquo;{comment.text}&rdquo;</p>
                </div>
              ) : (
                <div>
                  <p className="flex items-center line-clamp-3">
                    {post.featured && <StarIcon className="mr-1 h-4 w-4 text-brand-yellow" aria-hidden="true" />}
                    {post.title}
                  </p>
                  {post.links?.length > 0 && <p className="pt-2 font-medium">Links: {post.links.length}</p>}
                  {post.moderationHits?.total > 0 && (
                    <p className="pt-2 font-medium text-brand-amber">
                      Detected Moderation Keywords: {post.moderationHits.total}
                    </p>
                  )}
                </div>
              )}
            </div>
            {/* reaction and comment counts */}
            <div className="flex pt-6 items-center">
              <div className="mt-6 flex justify-between space-x-8">
                <div className="flex space-x-6">
                  <span className="inline-flex items-center text-sm">
                    <span className="inline-flex space-x-2 text-brand-black/70">
                      <HeartIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="font-medium text-brand-black/70">{post?.reactionsCount || 0}</span>
                    </span>
                  </span>
                  <span className="inline-flex items-center text-sm">
                    <span className="inline-flex space-x-2 text-brand-black/70">
                      <ChatBubbleLeftEllipsisIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="font-medium text-brand-black/70">{post?.commentsCount || 0}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </>
  );
