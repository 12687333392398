import {Channel, ChannelList, Chat, LoadingIndicator, MessageList, Thread, Window} from 'stream-chat-react';
import {Tab} from '@headlessui/react';

import 'stream-chat-react/dist/css/index.css';
import {StreamChat} from 'stream-chat';

const streamChatAppId = process.env.REACT_APP_STREAM_CHAT_APP_ID;
const streamChatClient = StreamChat.getInstance(streamChatAppId);
const sort = {last_message_at: -1};
const options = {limit: 100};

export const ProfileChatTab = ({profile}) => {
  if (!streamChatClient) {
    return <LoadingIndicator />;
  }

  return (
    profile && (
      <>
        <Tab.Panel>
          <Chat client={streamChatClient}>
            <ChannelList filters={{type: 'messaging', members: {$in: [profile.id]}}} sort={sort} options={options} />
            <Channel>
              <Window>
                <MessageList messageActions={[]} />
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </Tab.Panel>
      </>
    )
  );
};
