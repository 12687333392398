import {FlagIcon, LockClosedIcon} from '@heroicons/react/24/outline';
import {StarIcon} from '@heroicons/react/20/solid';
import {classNames} from '../utils';
import {MediaGrid} from './MediaGrid';

export const ProfileCard = ({profile, openSelectedProfile}) =>
  profile && (
    <>
      <div className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden shadow-sm hover:shadow-md">
        {/* post media carousel */}
        <div className="bg-gray-100 sm:h-64">
          {
            // TODO click the carousel to open the drawer with the side arrows still functional }
          }
          {!profile?.media?.length ? (
            <button onClick={() => openSelectedProfile({profileId: profile.id})} className="w-full h-64">
              &nbsp;
            </button>
          ) : (
            <button onClick={() => openSelectedProfile({profileId: profile.id})} className="w-full">
              <MediaGrid media={profile.media} />
            </button>
          )}
        </div>
        <button
          onClick={() => openSelectedProfile({profileId: profile.id})}
          className="w-full h-full text-left focus:outline-none"
        >
          <div className="flex-1 p-4 space-y-2 flex flex-col">
            <h3 className="text-sm font-medium text-brand-black">
              <a href={profile.href} onClick={() => openSelectedProfile({profileId: profile.id})}>
                <div className="flex items-center">
                  {profile.status === 'suspended' && (
                    <LockClosedIcon className="mr-1 h-4 w-4 text-brand-amber" aria-hidden="true" />
                  )}
                  {profile.featured && <StarIcon className="mr-1 h-4 w-4 text-brand-yellow" aria-hidden="true" />}
                  {profile.name}
                  {(profile.age || profile.gender) && (
                    <span className="pl-1 text-gray-500 text-sm font-normal">
                      ({profile.age}
                      {profile.person?.gender})
                    </span>
                  )}{' '}
                  <div className="grow">
                    {profile.moderation?.status === 'flagged' && (
                      <FlagIcon
                        className={classNames(
                          profile.moderation.color === 'blue' ? 'text-brand-jade' : 'text-brand-amber',
                          'h-5 w-5 ml-auto'
                        )}
                      />
                    )}
                  </div>
                </div>
                <p className="text-brand-jade/60 font-normal">{profile.realName}</p>
              </a>
            </h3>
            <div className="text-xs text-gray-700 h-10 line-clamp-3">{profile.tagline || profile.bio}</div>

            <div className="flex pt-3 items-center">
              <div className="grow text-xs text-brand-black text-left items-center truncate tracking-tight">
                {profile.location}
              </div>
              <div className="group flex rounded-md text-xs text-brand-black/40 text-right items-center  tracking-tight">
                <span className={classNames(profile.repDotColorClass, 'w-2.5 h-2.5 mr-1 rounded-full')} />
                <span>{profile.reputation}</span>
              </div>
            </div>
          </div>
        </button>
      </div>
    </>
  );
