import {useEffect, useState} from 'react';
import {Channel, ChannelHeader, Chat, MessageInput, MessageList, Window} from 'stream-chat-react';
import {StreamChat} from 'stream-chat';
import {API, graphqlOperation} from 'aws-amplify';
import {AppNavigation} from '../components/AppNavigation';

import {AppHeader} from '../components/AppHeader';
import 'stream-chat-react/dist/css/v2/index.css';
import './rooms.css';
import {classNames} from '../utils';

const streamChatAppId = process.env.REACT_APP_STREAM_CHAT_APP_ID;
const streamChatClient = StreamChat.getInstance(streamChatAppId);

export function RoomsPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(undefined);
  const [channel1, setChannel1] = useState(undefined);
  // const [channel2, setChannel2] = useState(undefined);
  // const [channel3, setChannel3] = useState(undefined);
  // const [channel4, setChannel4] = useState(undefined);

  useEffect(() => {
    (async () => {
      try {
        await fetchRooms();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  async function resetHandler() {
    try {
      // eslint-disable-next-line
      alert('search is not wired up on this page');
    } catch (e) {
      console.error('error resetting page');
      console.error(e);
      throw e;
    }
  }

  function handleSetRoom({room}) {
    console.log(`setting room ${room.id}`);
    setSelectedRoom(room);
    const streamChannel = streamChatClient.channel('room', room.id);
    setChannel1(streamChannel);
  }

  async function fetchRooms() {
    try {
      console.time('rooms');
      const response = await API.graphql(
        graphqlOperation(
          `query Rooms {
            rooms {
              items {
                chatChannelId
                color
                description
                id
                name
                tags {
                  items {
                    category
                    id
                    name
                  }
                }
              }
            }
          }`,
          {
            filters: {limit: 50},
            sortBy: 'recentActivity'
          }
        )
      );
      console.timeEnd('rooms');
      setRooms(response.data.rooms.items);
      if (!streamChatClient || channel1) return;

      const streamChannel1 = streamChatClient.channel('room', response.data.rooms.items[0].id);
      // const streamChannel2 = streamChatClient.channel('room', response.data.rooms.items[1].id);
      // const streamChannel3 = streamChatClient.channel('room', response.data.rooms.items[2].id);
      // const streamChannel4 = streamChatClient.channel('room', response.data.rooms.items[3].id);

      setChannel1(streamChannel1);
      // setChannel2(streamChannel2);
      // setChannel3(streamChannel3);
      // setChannel4(streamChannel4);
    } catch (e) {
      console.error('error fetching rooms');
      console.error(e);
    }
  }

  async function searchHandler({text}) {
    try {
      if (!text || !text.trim()) return;

      // eslint-disable-next-line
      alert('search is not wired up on this page');
      
      // setLoading(true);
    } catch (e) {
      console.error('error searching rooms');
      console.error(e);
      throw e;
    } finally {
      // setLoading(false);
    }
  }

  return (
    <>
      <div className="min-h-full  h-screen">
        {/* left and mobile nav */}
        <AppNavigation sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} selectedKey={'rooms'} />

        {/* setup main column */}
        <div className="lg:pl-48 flex flex-col">
          <AppHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            searchHandler={searchHandler}
            resetHandler={resetHandler}
          />

          {/* Main column */}
          <main className="flex-1 lg:border-t lg:border-gray-200 h-full">
            {/* Rooms table */}
            <div className=" block h-full">
              <div className="mt-8 align-middle inline-block min-w-full border-b border-red-200 h-full">
                <table className="min-w-full">
                  <thead>
                    <tr className="border-t border-gray-200">
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="lg:pl-2">Rooms</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    {rooms.map(room => (
                      <tr key={room.id}>
                        <td
                          className={classNames(
                            selectedRoom?.id === room.id && 'bg-amber-100',
                            'w-full max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'
                          )}
                        >
                          <button onClick={() => handleSetRoom({room})} className="align-middle">
                            <div className="flex items-center space-x-3 lg:pl-2">
                              <div
                                className="flex-shrink-0 w-2.5 h-2.5 rounded-full"
                                style={{
                                  backgroundColor: room.color
                                }}
                                aria-hidden="true"
                              />
                              <span>
                                <span className="truncate">{room.name}</span>
                                {' - '}
                                <span className="font-normal text-gray-500"> {room.description}</span>
                              </span>
                            </div>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Chat client={streamChatClient} theme="livestream light">
                  <div className="grid grid-cols-1 gap-4">
                    <div className="flex justify-center p-6 text-6xl bg-gray-100 border-2 border-gray-300 rounded-xl">
                      <Channel channel={channel1}>
                        <Window>
                          <ChannelHeader live />
                          <MessageList />
                          <MessageInput focus />
                        </Window>
                      </Channel>
                    </div>
                    {/*
                    <Channel channel={channel2}>
                      <Window>
                        <ChannelHeader live />
                        <MessageList />
                        <MessageInput focus />
                      </Window>
                    </Channel>
                    <Channel channel={channel3}>
                      <Window>
                        <ChannelHeader live />
                        <MessageList />
                        <MessageInput focus />
                      </Window>
                    </Channel>
                    <Channel channel={channel4}>
                      <Window>
                        <ChannelHeader live />
                        <MessageList />
                        <MessageInput focus />
                      </Window>
                    </Channel>
                    */}
                  </div>
                </Chat>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
