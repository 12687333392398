import {Fragment} from 'react';
import {Dialog, Tab, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';

export const TabbedDrawer = ({title, tabList, tabPanels, isOpen, closeHandler, defaultIndex = 0}) =>
  tabList &&
  tabPanels && (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeHandler}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-400 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-400 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white border-l-1 border-brand-jade shadow-xl">
                      <div>
                        <div className="pb-6">
                          <div className="px-4 flex mt-4">
                            <button
                              type="button"
                              className="absolute right-6 top-4 rounded-md text-brand-black/50 hover:text-brand-black/20 hover:ring-2 hover:ring-brand-black/20"
                              onClick={() => closeHandler()}
                            >
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                            <h3 className="text-3xl font-bold text-brand-black">{title}</h3>
                          </div>
                        </div>
                        <Tab.Group defaultIndex={defaultIndex}>
                          {tabList}
                          {tabPanels}
                        </Tab.Group>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
