import {Route, Routes} from 'react-router-dom';
import {NotFound404Page} from './pages/NotFound404Page';
import {SignInPage} from './pages/SignInPage';
import {ProfilesPage} from './pages/ProfilesPage';
import {DashboardPage} from './pages/DashboardPage';
import {PostsPage} from './pages/PostsPage';
import {RoomsPage} from './pages/RoomsPage';
import {StoriesPage} from './pages/StoriesPage';

export const Routing = () => (
  <Routes>
    <Route path="/" element={<DashboardPage />} />
    <Route path="/rooms" element={<RoomsPage />} />
    <Route path="/profiles" element={<ProfilesPage />} />
    <Route path="/posts" element={<PostsPage />} />
    <Route path="/stories" element={<StoriesPage />} />
    <Route path="/sign-in" element={<SignInPage />} />

    <Route path="*" element={<NotFound404Page />} />
  </Routes>
);
