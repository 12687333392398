import {Fragment, useState} from 'react';
import ReactPlayer from 'react-player/lazy';
import {Menu, Tab, Transition} from '@headlessui/react';
import {ChevronDoubleRightIcon} from '@heroicons/react/24/outline';
import {format} from 'date-fns';
import {API, graphqlOperation} from 'aws-amplify';
import {classNames} from '../utils';
import {LoadingSpinner} from './LoadingSpinner';
import {createModerationCase, resolveModerationCase} from '../graphql/mutations';

export const PostDrawerTab = ({post, updateCachedPostHandler, openMediaItemHandler}) => {
  const [loading, setLoading] = useState(false);

  async function setFeatured() {
    try {
      console.log(`setting featured for post ${post.id}`);
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(
          `mutation UpdatePost($postId: ID!, $input: UpdatePostInput!) {
                updatePost(postId: $postId, input: $input) {  
                  id
                }
            }`,
          {
            postId: post.id,
            input: {
              featured: true
            }
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not update post`);
        console.error(response.errors);
        return;
      }
      updateCachedPostHandler({
        post: {
          ...post,
          featured: true
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function unsetFeatured() {
    try {
      console.log(`unsetting featured for post ${post.id}`);
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(
          `mutation UpdatePost($postId: ID!, $input: UpdatePostInput!) {
                updatePost(postId: $postId, input: $input) {  
                  id
                }
            }`,
          {
            postId: post.id,
            input: {
              featured: false
            }
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not update post`);
        console.error(response.errors);
        return;
      }
      updateCachedPostHandler({
        post: {
          ...post,
          featured: false
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function deletePost() {
    try {
      console.log(`delete post ${post.id}`);
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(
          `mutation DeletePost($postId: ID!) {
                deletePost(postId: $postId) {  
                  result
                }
            }`,
          {
            postId: post.id
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not delete selected post`);
        console.error(response.errors);
        return;
      }
      updateCachedPostHandler({
        post: {
          ...post,
          deleted: true
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function hidePost() {
    try {
      console.log(`hide post ${post.id}`);
      setLoading(true);
      const input = {hidden: true};
      const response = await API.graphql(
        graphqlOperation(
          `mutation HidePost($postId: ID!, $input: UpdatePostInput!) {
                updatePost(postId: $postId, input: $input) {  
                  id
                }
            }`,
          {
            postId: post.id,
            input
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not hide selected post`);
        console.error(response.errors);
        return;
      }
      updateCachedPostHandler({
        post: {
          ...post,
          ...input
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function addFlag({comment}) {
    try {
      console.log(`adding flag to post ${post.id}`);
      setLoading(true);
      const result = await createModerationCase({targetId: post.id, comment});
      if (result === 'success')
        updateCachedPostHandler({
          post: {
            ...post,
            moderation: {
              ...post.moderation,
              status: 'flagged',
              totalReports: post.moderation?.totalReports || 1,
              color: 'blue',
              comment
            }
          }
        });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function removeFlag() {
    try {
      console.log(`resolving flagged post ${post.id}`);
      setLoading(true);
      const result = await resolveModerationCase({targetId: post.id});
      if (result === 'success')
        updateCachedPostHandler({
          post: {
            ...post,
            moderation: {
              ...post.moderation,
              status: 'resolved'
            }
          }
        });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function unhidePost() {
    try {
      console.log(`unhide post ${post.id}`);
      setLoading(true);
      const input = {hidden: false};
      const response = await API.graphql(
        graphqlOperation(
          `mutation UnHidePost($postId: ID!, $input: UpdatePostInput!) {
                updatePost(postId: $postId, input: $input) {  
                  id
                }
            }`,
          {
            postId: post.id,
            input
          }
        )
      );
      if (response?.errors?.length > 0) {
        console.error(`Could not unhide selected post`);
        console.error(response.errors);
        return;
      }
      updateCachedPostHandler({
        post: {
          ...post,
          ...input
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    post && (
      <>
        <Tab.Panel>
          <div className="px-4 py-5 sm:px-0 sm:py-0">
            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200 ">
              <div className="sm:flex sm:px-6 sm:py-3">
                <ul role="list" className="grid grid-cols-4 gap-x-1 gap-y-2">
                  {post.media?.length > 0 ? (
                    post.media.map(media => (
                      <li key={media.key} className="relative">
                        <button onClick={() => openMediaItemHandler({media})}>
                          {media.type === 'image' ? (
                            <img
                              src={media.url}
                              alt=""
                              className="object-cover pointer-events-none group-hover:opacity-75 h-56 mx-auto rounded-md"
                            />
                          ) : (
                            <ReactPlayer
                              url={media.url}
                              controls
                              height="224px"
                              width="153px"
                              className="object-cover pointer-events-none group-hover:opacity-75 h-56 mx-auto rounded-md object-fill bg-brand-black"
                            />
                          )}
                        </button>
                      </li>
                    ))
                  ) : (
                    <div>No Media</div>
                  )}
                </ul>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">Post ID</dt>
                <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  <a href={`highthere://groupfeed/post/${post.id}`} target="_blank" rel="noopener noreferrer">
                    {post.id}
                  </a>
                </dd>
                <div className="ml-2 inline-flex">
                  <Menu as="div" className="relative inline-block text-right">
                    <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white p-1 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-jade focus:ring-offset-2">
                      <ChevronDoubleRightIcon className="h-4 w-4" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none hover:opacity-100">
                        <div className="py-1">
                          <Menu.Item>
                            {({active}) => (
                              <span
                                className={classNames(
                                  active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm font-bold'
                                )}
                              >
                                {post.id}
                              </span>
                            )}
                          </Menu.Item>
                        </div>

                        {post.featured ? (
                          <div className="py-1">
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  onClick={unsetFeatured}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                    'flex px-4 py-2 text-sm w-full'
                                  )}
                                >
                                  <span>Unmark as Featured</span>
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        ) : (
                          <div className="py-1">
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  onClick={setFeatured}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                    'flex px-4 py-2 text-sm w-full'
                                  )}
                                >
                                  <span>Mark as Featured</span>
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        )}

                        {post.moderation?.status === 'flagged' ? (
                          <div className="py-1">
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  onClick={removeFlag}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                    'flex px-4 py-2 text-sm w-full'
                                  )}
                                >
                                  <span>Remove Flag</span>
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        ) : (
                          <div className="py-1">
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  onClick={addFlag}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                    'flex px-4 py-2 text-sm w-full'
                                  )}
                                >
                                  <span>Add Flag</span>
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        )}

                        <div className="py-1">
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={hidePost}
                                className={classNames(
                                  active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm w-full'
                                )}
                              >
                                <span>Hide</span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={unhidePost}
                                className={classNames(
                                  active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm w-full'
                                )}
                              >
                                <span>Unhide</span>
                              </button>
                            )}
                          </Menu.Item>
                        </div>

                        <div className="py-1">
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={deletePost}
                                className={classNames(
                                  active ? 'bg-gray-100 text-brand-black/90' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm w-full'
                                )}
                              >
                                <span className="italic text-brand-amber">Delete Post</span>
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <LoadingSpinner loading={loading} />
                </div>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">Author</dt>
                <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {post.authorProfile?.name}
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">Title</dt>
                <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {post.title}
                </dd>
              </div>
              {post.moderation?.status && (
                <div className="sm:flex sm:px-6 sm:py-3">
                  <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">
                    Moderation
                  </dt>
                  <dd
                    className={classNames(
                      post.moderation?.status === 'flagged' ? 'text-brand-amber' : 'text-brand-black/90',
                      'mt-1 text-sm sm:col-span-2 sm:mt-0 sm:ml-6 self-center'
                    )}
                  >
                    {post.moderation?.status === 'flagged'
                      ? `Flagged ${post.moderation?.totalReports} ${
                          post.moderation?.totalReports > 1 ? 'times' : 'time'
                        }`
                      : 'Resolved'}
                  </dd>
                </div>
              )}
              {post.moderationList && (
                <div className="sm:flex sm:px-6 sm:py-3">
                  <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">
                    Detected
                  </dt>
                  <dd className="mt-1 text-sm text-brand-amber sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                    {post.moderationList}
                  </dd>
                </div>
              )}
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">
                  Visibility
                </dt>
                <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {post.hidden ? 'Hidden' : 'Public'}
                </dd>
              </div>

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">
                  Created At
                </dt>
                <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {post.createdAt && format(new Date(post.createdAt), 'MM/dd/yy hh:mm a')}
                </dd>
              </div>

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">
                  Last Activity At
                </dt>
                <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {post.lastActivityAt && format(new Date(post.lastActivityAt), 'MM/dd/yy hh:mm a')}
                </dd>
              </div>
              {post.links?.length > 0 && (
                <div className="sm:flex sm:px-6 sm:py-3">
                  <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">Links</dt>
                  <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                    {post.links?.map((linkItem, index) => (
                      <p key={index}>
                        <a href={linkItem} target="_blank" rel="noopener noreferrer">
                          {linkItem}
                        </a>
                      </p>
                    ))}
                  </dd>
                </div>
              )}

              {post.mentions?.length > 0 && (
                <div className="sm:flex sm:px-6 sm:py-3">
                  <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">
                    Mentions
                  </dt>
                  <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                    {post.mentions?.map((mention, index) => (
                      <p key={index}>{mention.originalName}</p>
                    ))}
                  </dd>
                </div>
              )}

              {post.hashtags?.length > 0 && (
                <div className="sm:flex sm:px-6 sm:py-3">
                  <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">
                    Hashtags
                  </dt>
                  <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                    {post.hashtags?.map((hashtag, index) => (
                      <p key={index}>{hashtag}</p>
                    ))}
                  </dd>
                </div>
              )}

              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">Comments</dt>
                <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {post.commentsCount}
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-3">
                <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">Reactions</dt>
                <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                  {post.reactionsCount}
                </dd>
              </div>

              {post.text && (
                <div className="sm:flex sm:px-6 sm:py-3">
                  <dt className="text-sm font-medium text-brand-black/40 sm:flex-shrink-0 w-28 self-center">Text</dt>
                  <dd className="mt-1 text-sm text-brand-black/90 sm:col-span-2 sm:mt-0 sm:ml-6 self-center">
                    {post.text}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </Tab.Panel>
      </>
    )
  );
};
