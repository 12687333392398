import {useEffect, useState} from 'react';
import {Tab} from '@headlessui/react';
import {Comment} from './Comment';
import {postCommentsQuery} from '../graphql/queries';
import {LoadingSpinner} from './LoadingSpinner';
import {decorateCommentAuthorProfile} from '../utils';

export const PostCommentsDrawerTab = ({post, setCommentsTabCount}) => {
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (post?.id) await fetchCommentsForPost();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  async function fetchCommentsForPost() {
    try {
      setLoading(true);
      console.time('comments');
      const response = await postCommentsQuery({postId: post.id});
      console.timeEnd('comments');

      const commentItems =
        response.items?.map(item => ({
          ...item,
          authorProfile: decorateCommentAuthorProfile({authorProfile: item.authorProfile}),
          replies: item.replies?.items?.map(a => ({
            ...a,
            authorProfile: decorateCommentAuthorProfile({authorProfile: a.authorProfile}),
            replies: a.replies?.items?.map(b => ({
              ...b,
              authorProfile: decorateCommentAuthorProfile({authorProfile: b.authorProfile})
            }))
          }))
        })) || [];
      setComments(commentItems);
      setCommentsTabCount(response.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Tab.Panel>
        <div className="px-4 py-5 sm:px-0 sm:py-0">
          {(!comments || comments.length === 0) && (
            <div className="mx-auto py-6 px-4 text-sm h-16 text-brand-black/70">
              {loading ? <LoadingSpinner loading={loading} /> : <span>No Comments</span>}
            </div>
          )}
          <div className="space-y-0 ">
            {comments.map(comment => (
              <div key={comment.id}>
                <Comment comment={comment} />
                {comment.replies.map(a => (
                  <div key={a.id}>
                    <Comment key={a.id} comment={a} level={2} />
                    {a.replies.map(b => (
                      <div key={b.id}>
                        <Comment key={b.id} comment={b} level={3} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Tab.Panel>
    </>
  );
};
