export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const decoratePost = post => {
  if (!post) return undefined;

  const moderationList = [];
  if (post?.moderationHits?.blacklist) moderationList.push(...post.moderationHits.blacklist);
  if (post?.moderationHits?.keywords) moderationList.push(...post.moderationHits.keywords);
  if (post?.moderationHits?.symbols) moderationList.push(...post.moderationHits.symbols);

  return {
    ...post,
    hidden: !!post.hidden,
    moderationList: moderationList.join(', '),
    authorProfile: decorateProfile(post.authorProfile)
  };
};

export const decorateProfile = profile => {
  if (!profile) return undefined;

  const reputation = Number(profile.reputation || 0.5);
  const realName = [];
  if (profile.person?.givenName) realName.push(profile.person.givenName);
  if (profile.person?.familyName) realName.push(profile.person.familyName);
  const authorLocation = [];
  if (profile.location?.locality) authorLocation.push(profile.location.locality);
  if (profile.location?.region) authorLocation.push(profile.location.region);
  if (profile.location?.country) authorLocation.push(profile.location.country);

  return {
    ...profile,
    realName: realName.join(' '),
    reputation,
    repDotColorClass: reputation < 0.3 ? 'bg-brand-amber' : reputation < 0.7 ? 'bg-brand-yellow' : 'bg-brand-green',
    repRingColorClass:
      reputation < 0.3 ? 'ring-brand-amber' : reputation < 0.7 ? 'ring-brand-yellow' : 'ring-brand-green',
    location: (authorLocation.length > 0 && authorLocation.join(', ')) || undefined,
    authMethods: profile.person?.usernames?.map(a => a.split('_')[0]) || [],
    person: {
      ...profile.person,
      gender: profile.person?.gender?.slice(0, 1).toUpperCase()
    }
  };
};

export const decorateCommentAuthorProfile = ({authorProfile}) => {
  const reputation = Number(authorProfile?.reputation || 0.5);
  return {
    ...authorProfile,
    reputation,
    repDotColorClass: reputation < 0.3 ? 'bg-brand-amber' : reputation < 0.7 ? 'bg-brand-yellow' : 'bg-brand-green',
    repRingColorClass:
      reputation < 0.3 ? 'ring-brand-amber' : reputation < 0.7 ? 'ring-brand-yellow' : 'ring-brand-green'
  };
};
