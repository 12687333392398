import ReactPlayer from 'react-player/lazy';
import {classNames} from '../utils';

export const TinyPostCard = ({post, open}) =>
  post && (
    <>
      <div className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden shadow-sm hover:shadow-md">
        {/* post author */}
        <button onClick={() => open({post})} className="w-full text-left focus:outline-none">
          <div className="flex space-x-1 pt-2 pl-1 pb-1 pr-1">
            <img
              className={classNames(
                post?.authorProfile?.repRingColorClass,
                'h-4 w-4 bg-brand-black/20 max-w-none rounded-full ring-2 object-cover object-center'
              )}
              src={post?.authorProfile?.cover || post?.authorProfile?.avatar}
              alt=""
            />
            <div className="text-xs font-medium text-brand-black/90 hover:underline">{post?.authorProfile?.name}</div>

            <div className="min-w-0 flex-1"></div>
          </div>
        </button>

        {/* post media carousel */}
        <div className="bg-gray-200 h-28">
          {!post?.media?.length ? (
            <button onClick={() => open({post})} className="w-full h-28">
              &nbsp;
            </button>
          ) : (
            post?.media?.length >= 1 &&
            (post.media[0].type === 'image' ? (
              <button onClick={() => open({post})} className="w-full h-28">
                <img
                  loading="lazy"
                  src={post.media[0].url}
                  className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                  alt=""
                />
              </button>
            ) : (
              <ReactPlayer
                url={post.media[0].url}
                controls
                muted
                height="100%"
                width="100%"
                className="object-cover bg-brand-black"
              />
            ))
          )}
        </div>
        {/* post title and info */}
        <button onClick={() => open({post})} className="w-full h-full text-left focus:outline-none">
          <div className="flex-1 p-2 space-y-2 flex flex-col">
            <div className="text-xs text-brand-black">
              <div>
                <p className="line-clamp-2">{post.title}</p>
              </div>
            </div>
          </div>
        </button>
      </div>
    </>
  );
