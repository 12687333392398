import {useEffect, useState} from 'react';
import {Tab} from '@headlessui/react';
import {postReactionsQuery} from '../graphql/queries';
import {LoadingSpinner} from './LoadingSpinner';
import {decorateCommentAuthorProfile} from '../utils';

export const PostReactionsDrawerTab = ({post, setReactionsTabCount}) => {
  const [loading, setLoading] = useState(false);
  const [reactions, setReactions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (post?.id) await fetchReactionsForPost();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  async function fetchReactionsForPost() {
    try {
      setLoading(true);
      console.time('reactions');
      const response = await postReactionsQuery({postId: post.id});
      console.timeEnd('reactions');

      const reactionItems =
        response.items?.map(item => ({
          ...item,
          authorProfile: decorateCommentAuthorProfile({authorProfile: item.authorProfile})
        })) || [];
      setReactions(reactionItems);
      setReactionsTabCount(reactionItems?.length || 0);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Tab.Panel>
        <div className="px-4 py-5 sm:px-0 sm:py-0">
          {(!reactions || reactions.length === 0) && (
            <div className="mx-auto py-6 px-4 text-sm h-16 text-brand-black/70">
              {loading ? <LoadingSpinner loading={loading} /> : <span>Coming Soon</span>}
            </div>
          )}
          <div className="space-y-0 ">
            {reactions.map(reaction => (
              <div key={reaction.id}>{reaction.createdAt}</div>
            ))}
          </div>
        </div>
      </Tab.Panel>
    </>
  );
};
