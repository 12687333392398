import {API, graphqlOperation} from 'aws-amplify';

export const createAdminChatToken = async () => {
  const response = await API.graphql(
    graphqlOperation(
      `mutation ChatToken($userId: ID!) {
              createChatToken(userId: $userId)
            }`,
      {
        userId: 'usr-streamchat-admin'
      }
    )
  );
  return response.data.createChatToken;
};

export const createModerationCase = async ({targetId, note}) => {
  const response = await API.graphql(
    graphqlOperation(
      `mutation CreateModerationCase($targetId: ID!, $note: String) {
                createModerationCase(targetId: $targetId, note: $note) {  
                  result
                }
            }`,
      {
        targetId,
        note
      }
    )
  );
  if (response?.errors?.length > 0) {
    console.error(`Could not create moderation case`);
    console.error(response.errors);
    return 'failure';
  }
  return 'success';
};

export const saveModerationNote = async ({targetId, note}) => {
  const response = await API.graphql(
    graphqlOperation(
      `mutation SaveModerationNote($targetId: ID!, $note: String) {
                saveModerationNote(targetId: $targetId, note: $note) {  
                  result
                }
            }`,
      {
        targetId,
        note
      }
    )
  );
  if (response?.errors?.length > 0) {
    console.error(`Could not save moderation note`);
    console.error(response.errors);
    return 'failure';
  }
  return 'success';
};

export const resolveModerationCase = async ({targetId}) => {
  const response = await API.graphql(
    graphqlOperation(
      `mutation ResolveModerationCase($targetId: ID!) {
                resolveModerationCase(targetId: $targetId) {  
                  result
                }
            }`,
      {
        targetId
      }
    )
  );
  if (response?.errors?.length > 0) {
    console.error(`Could not resolve moderation case`);
    console.error(response.errors);
    return 'failure';
  }
  return 'success';
};
