import {API, graphqlOperation} from 'aws-amplify';

export const selfQuery = async () => {
  const response = await API.graphql(
    graphqlOperation(`query Self {
        self {
            ...on SelfUser {
                id
                name
                avatar
                cover
                person {
                  givenName
                  familyName
                }
              }
            }
        }`)
  );
  return response.data.self;
};

export const search = async ({text, hashtags, postType, nextToken, limitPerType = 30}) => {
  const response = await API.graphql(
    graphqlOperation(
      `query Search($input: SearchInput, $nextToken: String, $filters: SearchFiltersInput) {
        search(input: $input, nextToken: $nextToken, filters: $filters) {
          posts {
            items {
              id
              title
              authorProfile {
                ... on UserSummary {
                  id
                  name
                  age
                  avatar
                  bio
                  cover
                  createdAt
                  lastSeenAt
                  location {
                    country
                    locality
                    region
                  }
                  media {
                    url
                    type
                    thumbnailUrl
                    key
                  }
                  person {
                    dob
                    email
                    familyName
                    gender
                    givenName
                    phone
                    usernames
                  }
                  reputation
                  featured
                  roles
                  status
                  tagline
                  updatedAt
                  verified {
                    phone
                  }
                }
              }
              type
              text
              media {
                key
                thumbnailUrl
                type
                url
              }
              lastActivityAt
              links
              createdAt
              groupId
              hashtags
              moderation {
                status
                totalReports
                color
                comment
              }
              hidden
              featured
            }
            nextToken
            total
          }
          profiles {
            items {
              ... on UserSummary {
                id
                name
                age
                avatar
                bio
                cover
                createdAt
                lastSeenAt
                location {
                  country
                  locality
                  region
                }
                media {
                  type
                  url
                  thumbnailUrl
                  key
                }
                person {
                  dob
                  email
                  familyName
                  gender
                  givenName
                  phone
                  usernames
                }
                reputation
                roles
                status
                tagline
                updatedAt
                verified {
                  phone
                }
                moderation {
                  status
                  totalReports
                  color
                  comment
                }
                featured
              }
            }
            nextToken
            total
          }
          nextToken
        }
        
        
        }`,
      {
        input: {
          text,
          hashtags
        },
        nextToken,
        filters: {
          limitPerType,
          postType
        }
      }
    )
  );
  return response.data.search;
};

export const postQuery = async ({postId}) => {
  const response = await API.graphql(
    graphqlOperation(
      `query Post($id: ID!) {
                post(id: $id) {
                  title
                  type
                  text
                  reactionsCount
                  mentions {
                    profileId
                    originalName
                  }
                  media {
                    url
                    key
                    type
                  }
                  links
                  lastActivityAt
                  id
                  hashtags
                  groupId
                  createdAt
                  commentsCount
                  moderationHits {
                    blacklist
                    keywords
                    symbols
                    total
                  }
                  hidden
                  featured
                  authorId
                  authorProfile {
                    ... on UserSummary {
                      id
                      name
                      person {
                        dob
                        email
                        familyName
                        gender
                        givenName
                        phone
                        usernames
                      }
                      roles
                      lastSeenAt
                      interestsFormatted
                      createdAt
                      cover
                      counts {
                        comments
                        connectionRequests
                        connections
                        posts
                        reactions
                      }
                      location {
                        locality
                        region
                        country
                      }
                      media {
                        url
                      }
                      bio
                      avatar
                      age
                      status
                      tagline
                      updatedAt
                      reputation
                      featured
                    }
                  }
                }
              }`,
      {
        id: postId
      }
    )
  );
  return response.data?.post;
};

export const profileQuery = async ({profileId}) => {
  const response = await API.graphql(
    graphqlOperation(
      `query Profile($id: ID!) {
            profile(id: $id) {
              ... on User {
                id
                name
                person {
                  dob
                  email
                  familyName
                  gender
                  givenName
                  phone
                  usernames
                }
                roles
                lastSeenAt
                interestsFormatted
                createdAt
                cover
                counts {
                  comments
                  connectionRequests
                  connections
                  posts
                  reactions
                }
                location {
                  locality
                  region
                  country
                }
                media {
                  url
                  key
                  type
                }
                bio
                avatar
                age
                status
                tagline
                updatedAt
                reputation
                featured
                moderation {
                  status
                  totalReports                  
                  color
                  comment
                }
                mobileDeviceInfo {
                  demographics {
                    platform
                  }
                }
              }
            }
          }`,
      {
        id: profileId
      }
    )
  );
  return response.data?.profile;
};

export const profilesQuery = async ({nextToken, filters, sortBy}) => {
  const response = await API.graphql(
    graphqlOperation(
      `query Profiles($nextToken: String, $filters: QueryFiltersInput, $sortBy: SortBy) {
            profiles(nextToken: $nextToken, filters: $filters, sortBy: $sortBy) {
              nextToken
              items {
                ... on UserSummary {
                  id
                  name
                  person {
                    dob
                    email
                    familyName
                    gender
                    givenName
                    phone
                    usernames
                  }
                  roles
                  lastSeenAt
                  interestsFormatted
                  createdAt
                  cover
                  counts {
                    comments
                    connectionRequests
                    connections
                    posts
                    reactions
                  }
                  location {
                    locality
                    region
                    country
                  }
                  media {
                    url
                    key
                    type
                  }
                  bio
                  avatar
                  age
                  status
                  tagline
                  updatedAt
                  reputation
                  featured
                  moderation {
                    status
                    totalReports                    
                    color
                    comment
                  }
                }
              }
            }
          }`,
      {
        nextToken,
        filters,
        sortBy
      }
    )
  );
  return {
    items: response.data?.profiles?.items || [],
    nextToken: response?.data?.profiles?.nextToken
  };
};

export const postsQuery = async ({nextToken, filters, sortBy}) => {
  const response = await API.graphql(
    graphqlOperation(
      `query Posts($nextToken: String, $filters: QueryFiltersInput, $sortBy: SortBy) {
                posts(nextToken: $nextToken, filters: $filters, sortBy: $sortBy) {
                    nextToken
                    items {
                      title
                      type
                      text
                      reactionsCount
                      mentions {
                        profileId
                        originalName
                      }
                      media {
                        url
                        key
                        thumbnailUrl
                        type
                      }
                      links
                      lastActivityAt
                      id
                      hashtags
                      groupId
                      createdAt
                      commentsCount
                      moderationHits {
                        blacklist
                        keywords
                        symbols
                        total
                      }
                      hidden
                      featured
                      moderation {
                        status
                        totalReports                        
                        color
                        comment
                      }
                      authorId
                      authorProfile {
                        ... on UserSummary {
                          id
                          name
                          person {
                            dob
                            email
                            familyName
                            gender
                            givenName
                            phone
                            usernames
                          }
                          roles
                          lastSeenAt
                          interestsFormatted
                          createdAt
                          cover
                          counts {
                            comments
                            connectionRequests
                            connections
                            posts
                            reactions
                          }
                          location {
                            locality
                            region
                            country
                          }
                          media {
                            url
                            key
                            thumbnailUrl
                            type
                          }
                          bio
                          avatar
                          age
                          status
                          tagline
                          updatedAt
                          reputation
                          featured
                        }
                      }
                    }
                  }
            }`,
      {
        nextToken,
        filters,
        sortBy
      }
    )
  );
  return {
    items: response.data?.posts?.items || [],
    nextToken: response?.data?.posts?.nextToken
  };
};

export const profilePostsQuery = async ({profileId, nextToken, filters}) => {
  const response = await API.graphql(
    graphqlOperation(
      `query ProfilePosts($profileId: ID!, $nextToken: String, $filters: QueryFiltersInput) {
                profile(id: $profileId){
                  ... on User {
                    posts(nextToken: $nextToken filters: $filters) {
                        nextToken
                        total
                        items {
                          title
                          type
                          text
                          reactionsCount
                          mentions {
                            profileId
                            originalName
                          }
                          media {
                            url
                            key
                            type
                          }
                          links
                          lastActivityAt
                          id
                          hashtags
                          groupId
                          createdAt
                          commentsCount
                          moderationHits {
                            blacklist
                            keywords
                            symbols
                            total
                          }
                          hidden
                          featured
                          moderation {
                            status
                            totalReports                            
                            color
                            comment
                          }
                          authorId
                          authorProfile {
                            ... on UserSummary {
                              id
                              name
                              person {
                                dob
                                email
                                familyName
                                gender
                                givenName
                                phone
                                usernames
                              }
                              roles
                              lastSeenAt
                              interestsFormatted
                              createdAt
                              cover
                              counts {
                                comments
                                connectionRequests
                                connections
                                posts
                                reactions
                              }
                              location {
                                locality
                                region
                                country
                              }
                              media {
                                url
                              }
                              bio
                              avatar
                              age
                              status
                              tagline
                              updatedAt
                              reputation
                              featured
                            }
                          }
                        }
                      }
                    }
                  }
                }`,
      {
        profileId,
        nextToken,
        filters
      }
    )
  );
  return {
    items: response.data?.profile?.posts?.items || [],
    nextToken: response?.data?.profile?.posts?.nextToken,
    total: response?.data?.profile?.posts?.total
  };
};

export const profileCommentsQuery = async ({profileId, nextToken, limit}) => {
  const response = await API.graphql(
    graphqlOperation(
      `query ProfileComments($profileId: ID!, $nextToken: String, $limit: Int) {
          profile(id: $profileId) {
            ... on User {
              comments(nextToken: $nextToken, limit: $limit, bundleReplies: false) {
                items {
                  id
                  text
                  createdAt
                  authorProfile{
                    ...on UserSummary{
                      avatar
                      cover
                      name
                      reputation
                      featured
                      id
                    }
                  }
                  postId
                  post {
                    id
                    title
                    authorProfile {
                      ... on UserSummary {
                        id
                        name
                        avatar
                        cover
                        reputation
                        featured
                      }
                    }
                    commentsCount
                    createdAt
                    groupId
                    hashtags
                    hidden
                    featured
                    lastActivityAt
                    links
                    text
                    type
                    media {
                      key
                      thumbnailUrl
                      type
                      url
                    }
                    mentions {
                      originalName
                      profileId
                    }
                    moderationHits {
                      blacklist
                      keywords
                      symbols
                      total
                    }
                    reactionsCount
                  }                    
                }
                nextToken
              }
            }
          }
        }`,
      {
        profileId,
        nextToken,
        limit
      }
    )
  );
  return {
    items: response.data?.profile?.comments?.items || [],
    nextToken: response?.data?.profile?.comments?.nextToken
  };
};

export const postCommentsQuery = async ({postId}) => {
  const response = await API.graphql(
    graphqlOperation(
      `query PostComments($id: ID!, $bundleReplies: Boolean) {
                post(id: $id) {  
                  comments(bundleReplies: $bundleReplies){
                    nextToken
                    total
                    items{
                      id
                      text
                      createdAt
                      authorProfile{
                        ...on UserSummary{
                          avatar
                          cover
                          name
                          reputation
                          featured
                          id
                        }
                      }
                      replies{
                        items{
                          id
                          text
                          createdAt
                          authorProfile{
                            ...on UserSummary{
                              avatar
                              cover
                              name
                              reputation
                              featured
                              id
                            }
                          }
                          replies{
                            items{
                              id
                              text
                              createdAt
                              authorProfile{
                                ...on UserSummary{
                                  avatar
                                  cover
                                  name
                                  reputation
                                  featured
                                  id
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
            }`,
      {
        id: postId,
        bundleReplies: true
      }
    )
  );
  return {
    items: response.data?.post?.comments?.items || [],
    nextToken: response?.data?.post?.comments?.nextToken,
    total: response?.data?.post?.comments?.total || 0
  };
};

export const postReactionsQuery = async ({postId}) => {
  const response = await API.graphql(
    graphqlOperation(
      `query PostReactions($id: ID!) {
                post(id: $id) {  
                  reactions{
                    nextToken
                    items{
                      id
                      reaction
                      createdAt
                    }
                  }
                }
            }`,
      {
        id: postId
      }
    )
  );
  return {
    items: response.data?.post?.reactions?.items || [],
    nextToken: response?.data?.post?.reactions?.nextToken,
    total: response?.data?.post?.reactions?.total
  };
};

export const postModerationQuery = async ({postId}) => {
  // TODO ignoring pagination for now
  const response = await API.graphql(
    graphqlOperation(
      `query PostModeration($id: ID!, $nextToken: String) {
                post(id: $id) {  
                  moderationNote
                  moderationReports(nextToken: $nextToken) {
                    items {
                      createdAt
                      id
                      isAdmin
                      offense
                      comment
                      sourceEmail
                      sourceId
                      sourceName
                      targetAuthorEmail
                      targetAuthorName
                      targetAuthorId
                      targetId
                    }
                    nextToken
                  }
                }
            }`,
      {
        id: postId
      }
    )
  );
  return {
    note: response.data?.post?.moderationNote,
    reports: response.data?.post?.moderationReports?.items || []
  };
};

export const profileModerationQuery = async ({profileId}) => {
  // TODO ignoring pagination for now
  const response = await API.graphql(
    graphqlOperation(
      `query ProfileModeration($id: ID!, $nextToken: String) {
                profile(id: $id) {  
                  ...on User {
                    moderationNote
                    moderationReports(nextToken: $nextToken) {
                      items {
                        createdAt
                        id
                        isAdmin
                        offense
                        comment
                        sourceEmail
                        sourceId
                        sourceName
                        targetAuthorEmail
                        targetAuthorName
                        targetAuthorId
                        targetId
                      }
                      nextToken
                    }
                  }                  
                }
            }`,
      {
        id: profileId
      }
    )
  );
  return {
    note: response.data?.profile?.moderationNote,
    reports: response.data?.profile?.moderationReports?.items || []
  };
};
