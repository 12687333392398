import {StreamChat} from 'stream-chat';
import {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Routing} from './Routing';
import {createAdminChatToken} from './graphql/mutations';

const streamChatAppId = process.env.REACT_APP_STREAM_CHAT_APP_ID;

export default function App() {
  useEffect(() => {
    (async () => {
      try {
        const token = await createAdminChatToken();
        const streamChatClient = StreamChat.getInstance(streamChatAppId);
        await streamChatClient.connectUser(
          {
            id: 'usr-streamchat-admin'
          },
          token
        );
        console.log('connected to chat');
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  );
}
