import {Tab} from '@headlessui/react';
import {useState} from 'react';
import {ProfileDrawerTab} from './ProfileDrawerTab';
import {ProfileCommentsDrawerTab} from './ProfileCommentsDrawerTab';
import {MediaItemViewer} from './MediaItemViewer';
import {PostsDrawerTab} from './PostsDrawerTab';
import {ProfileChatTab} from './ProfileChatTab';
import {ModerationReportsDrawerTab} from './ModerationReportsDrawerTab';

export const ProfileDrawerTabPanels = ({
  profile,
  updateCachedProfileHandler,
  setPostsTabCount,
  setCommentsTabCount,
  setModerationTabCount
}) => {
  const [mediaItem, setMediaItem] = useState(undefined);

  function closeMediaItemViewer() {
    setMediaItem(undefined);
  }

  function openMediaItemViewer({media}) {
    setMediaItem(media);
  }

  return (
    profile && (
      <>
        <Tab.Panels>
          <ProfileDrawerTab
            profile={profile}
            openMediaItemHandler={openMediaItemViewer}
            updateCachedProfileHandler={updateCachedProfileHandler}
          />
          <PostsDrawerTab profile={profile} setPostsTabCount={setPostsTabCount} />
          <ProfileCommentsDrawerTab profile={profile} setCommentsTabCount={setCommentsTabCount} />
          <ProfileChatTab profile={profile} />
          <ModerationReportsDrawerTab target={profile} setModerationTabCount={setModerationTabCount} />
        </Tab.Panels>
        <MediaItemViewer onClose={closeMediaItemViewer} mediaItem={mediaItem} />
      </>
    )
  );
};
