import {Tab} from '@headlessui/react';
import {useEffect, useState} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {profileCommentsQuery} from '../graphql/queries';
import {classNames, decorateCommentAuthorProfile, decoratePost} from '../utils';
import {TinyPostCard} from './TinyPostCard';
import {Comment} from './Comment';
// import {classNames} from '../utils';

export const ProfileCommentsDrawerTab = ({profile, setCommentsTabCount}) => {
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: !!nextToken,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 300px 0px'
  });

  useEffect(() => {
    (async () => {
      try {
        const profileId = profile.id;
        if (profileId) await fetchCommentsForProfile();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  async function handleLoadMore() {
    try {
      setLoading(true);
      await fetchCommentsForProfile();
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchCommentsForProfile() {
    try {
      const cachedComments = [...comments];

      console.time('comments');
      const response = await profileCommentsQuery({
        profileId: profile.id,
        nextToken,
        limit: 20
      });
      console.timeEnd('comments');

      const commentItems = [
        ...cachedComments,
        ...response.items.map(a => ({
          ...a,
          authorProfile: decorateCommentAuthorProfile({authorProfile: a.authorProfile}),
          post: decoratePost(a.post)
        }))
      ];

      console.log(response);

      setComments(commentItems);
      setNextToken(response.nextToken);
      setCommentsTabCount(profile.counts?.comments || 0);
    } catch (e) {
      console.error(e);
    }
  }

  function openPostInNewTab({post}) {
    if (!post?.id) return;
    window.open(`/posts?id=${post.id}`, '_blank', 'noopener,noreferrer');
  }

  return (
    <>
      <Tab.Panel>
        <div className={classNames(comments?.length > 0 ? 'py-2' : 'py-6', 'mx-auto px-4')}>
          {(!comments || comments.length === 0) && <div className="text-sm h-16 text-brand-black/70">No Comments</div>}
          {comments?.length > 0 && (
            <div className="grid grid-cols-1 gap-x-6 divide-y divide-gray-200">
              {comments?.map(comment => (
                <div key={comment.id} className="flex pt-1 pb-3">
                  <div className="w-48 pt-2">
                    <TinyPostCard post={comment.post} open={openPostInNewTab} />
                  </div>
                  <div className="w-full py-1">
                    <Comment comment={comment} italicizeText={true} />
                  </div>
                </div>
              ))}
              <div>
                <div ref={sentryRef}></div>
                {nextToken ? (
                  <div className="border border-brand-jade shadow rounded-md p-4 mt-6 max-w-sm w-full mx-auto w-36">
                    <div className="animate-pulse flex space-x-4">
                      <div className="rounded-full bg-brand-jade/70 h-10 w-10"></div>
                      <div className="flex-1 space-y-6 py-1">
                        <div className="h-12 bg-brand-jade/40 rounded"></div>
                        <div className="space-y-3">
                          <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-brand-jade/70 rounded col-span-2"></div>
                            <div className="h-2 bg-brand-jade/70 rounded col-span-1"></div>
                          </div>
                          <div className="h-2 bg-brand-jade/70 rounded"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>No more results</div>
                )}
              </div>
            </div>
          )}
        </div>
      </Tab.Panel>
    </>
  );
};
